.booklist {
  width: 840px;
  margin-top: 20px;
  .title {
    font-size: 20px;
    color: $gray;
    font-weight: bold;
    small {
      font-size: 16px;
      color: $gray-light;
      margin-left: 40px;
      font-weight: normal;

    }
    a {
      color: $gray-dark;
      font-weight: bold;
      &:hover {
        color:$color-hover;
      }
      &:active {
        color:$color-active;
      }
    }
  }
  .sub{
    font-size: 13px;
    color: $gray;
    margin: 10px 0 20px 0;
  }
  .desc{
    font-size: 13px;
    line-height: 1.5em;
    color: $gray-dark;
  }
}