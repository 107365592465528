.banner_puzzle_bak {
  height: 380px;
  overflow: hidden;
  .swiper-container {
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
  }

  .swiper-slide {
    height: 380px;
    .puzzle {
      li {
        position: relative;
        display: block;
        background-size: cover;
        float: left;
        overflow: hidden;
        &.pos-1 {
          width: 510px;
          height: 380px;
        }
        &.pos-2 {
          width: 336px;
          height: 185px;
          margin-left: 9px;
        }
        &.pos-3 {
          width: 221px;
          height: 185px;
          float: right;
          margin: 10px 0 0 9px;
        }
        .bottom {
          position: absolute;
          width: 100%;
          height: 86px;
          bottom: 0;
          left: 0;
          background-image: url(../images/sd/banner-bg.png);
        }
        .bottom .title {
          padding-left: 10px;
          font-size: 20px;
          color: #FFFFFF;
          font-weight: bold;
          position: absolute;
          bottom: 30px;
        }
        .bottom .subtitle {
          padding-left: 10px;
          font-size: 14px;
          color: #FFFFFF;
          position: absolute;
          bottom: 10px;
        }

        ///* Rectangle 7: */

      }
    }
  }
  .pagination {
    position: absolute;
    z-index: 20;
    left: 10px;
    bottom: 10px;
    width: 510px - 20px;
    text-align: right;
  }

  .swiper-pagination-switch {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #D9D9D9;
    margin-right: 5px;
    cursor: pointer;
  }

  .swiper-visible-switch {
    background: $color-hover;
  }

  .swiper-active-switch {
    width: 23px;
    background: $color-main;
  }

}
