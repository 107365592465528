.vip-pay-item{
  ul{
    @include clearfix();
    margin: 0 -41px;
    $h:100px;
    li{
      float: left;
      width: 238px;
      height: $h;
      margin: 5px 41px;
      border: 1px solid $color-border;
      border-radius: 10px;
      overflow: hidden;
      .time-wrap{
        float: left;
        width: 99px;
        height: $h;
        line-height: $h;
        border-right: 1px dashed $color-border;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        .time{
          font-size: 30px;
        }
      }
      .price-wrap,.pay-wrap{
        height: $h/2;
        line-height: $h/2;
        text-align: center;
        margin-left: 99px;
      }
      .price-wrap{
        border-bottom: 1px dashed $color-border;
        .sign{
          color: $gray-dark;
        }
        .price{
          font-size: 30px;
          margin-right: 10px;
        }
        .original{
          color: $gray-light;
          text-decoration: line-through;
        }
      }
      .pay-wrap{
        a {
          @include a-link();
        }
      }
    }
  }
}