@font-face {
    font-family: "icon";
    src: url('../fonts/icon.eot');
    src: url('../fonts/icon.eot?#iefix') format('eot'),
    url('../fonts/icon.woff') format('woff'),
    url('../fonts/icon.ttf') format('truetype'),
    url('../fonts/icon.svg#icon') format('svg');
    font-weight: normal;
    font-style: normal;
}
.icon {
    display: inline-block;
    font-family: "icon";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}
.icon-lg {
                     font-size: 1.3333333333333333em;
                     line-height: 0.75em;
                     vertical-align: -15%;
                 }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                     width: 1.2857142857142858em;
                     text-align: center;
                 }

.button-small .icon:before, .button-small.icon:before{
  margin-top: 0px;
  vertical-align: middle;
}

.button .icon:before, .button.icon:before {
  vertical-align: middle;
}

.icon-arrow-a-down:before { content: "\EA01" }
.icon-arrow-a-up:before { content: "\EA02" }
.icon-arrow-b-up:before { content: "\EA03" }
.icon-arrow-down:before { content: "\EA04" }
.icon-arrow-l:before { content: "\EA05" }
.icon-arrow-up:before { content: "\EA06" }
.icon-arrow:before { content: "\EA07" }
.icon-bag:before { content: "\EA08" }
.icon-bookrack:before { content: "\EA09" }
.icon-box:before { content: "\EA0A" }
.icon-cart:before { content: "\EA0B" }
.icon-cat:before { content: "\EA0C" }
.icon-clock:before { content: "\EA0D" }
.icon-clock2:before { content: "\EA0E" }
.icon-close:before { content: "\EA0F" }
.icon-close2:before { content: "\EA10" }
.icon-copyright:before { content: "\EA11" }
.icon-d-caret:before { content: "\EA12" }
.icon-date:before { content: "\EA13" }
.icon-down:before { content: "\EA14" }
.icon-edit:before { content: "\EA15" }
.icon-edit2:before { content: "\EA16" }
.icon-end:before { content: "\EA17" }
.icon-eq:before { content: "\EA18" }
.icon-face-2:before { content: "\EA19" }
.icon-face:before { content: "\EA1A" }
.icon-fav:before { content: "\EA1B" }
.icon-fav2-full:before { content: "\EA1C" }
.icon-fav2:before { content: "\EA1D" }
.icon-history:before { content: "\EA1E" }
.icon-hot:before { content: "\EA1F" }
.icon-imgup:before { content: "\EA20" }
.icon-info:before { content: "\EA21" }
.icon-lamp:before { content: "\EA22" }
.icon-lock:before { content: "\EA23" }
.icon-ok:before { content: "\EA24" }
.icon-password:before { content: "\EA25" }
.icon-pull:before { content: "\EA26" }
.icon-rank:before { content: "\EA27" }
.icon-search:before { content: "\EA28" }
.icon-star-full:before { content: "\EA29" }
.icon-star:before { content: "\EA2A" }
.icon-subtract:before { content: "\EA2B" }
.icon-tel:before { content: "\EA2C" }
.icon-ticket:before { content: "\EA2D" }
.icon-up:before { content: "\EA2E" }
.icon-vip:before { content: "\EA2F" }
.icon-wallet:before { content: "\EA30" }
.icon-wechat:before { content: "\EA31" }
.icon-weibo:before { content: "\EA32" }
