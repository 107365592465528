.mh-list {
  @include clearfix();
  &, & >li {
    display: block;
    float: left;
    margin: 0;
    padding: 0;
  }
  &.col7 {
    margin: 0 -2.5px -20px -2.5px;
    &>li {
      width: 167px;
      height: 287px;
      margin: 0 2.5px 20px 2.5px;
    }
  }
  &.col3 {
    margin: 0 -22px -20px -22px;
    &>li {
      width: 363px;
      height: 220px;
      margin: 0 22px 20px 22px;
    }
  }
  &.col1 {
    margin: 0 0 -20px 0;
    &>li {
      margin: 0 0 20px 0;
    }
  }
  &.top-cat {
    margin: 0 -22px -20px -22px;
    &>li {
      height: auto;
      margin-bottom: 40px;
      margin: 0 22px 20px 22px;
      width: 363px;
      div:hover{
        background-color: $bg-gray;
      }
      .mh-itme-top div>a{
        display: block;
        width: 100%;
        height: 100%;
        padding-left: 5px;
      }
      &>.title {
        height: 38px;
        line-height: 38px;
        font-size: 24px;
        color: $gray-light;
        border-bottom: 1px dashed $color-border;
        margin-bottom: 20px;
        .more {
          color: $gray-light;
          font-size: 14px;
          float: right;
          &:hover{
            color: $color-hover;
          }
          &:active{
            color: $color-active;
          }
        }
      }
      .show-all-top {
        display: block;
        margin-top: 10px;
        color: $color-main;
        font-size: 14px;
        &:hover {
          color: $color-hover;
        }
        &:active {
          color: $color-active;
        }
      }
      //// 弹出修正
      //&:nth-child(3n) {
      //  .mh-itme-top:hover {
      //    .mh-tip-wrap {
      //      left: auto;
      //      right: 100%;
      //    }
      //  }
      //}

    }
  }
}