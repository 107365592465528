.booklist-details{
  padding:30px 0;
  padding-top: 0;
  @include clearfix();
  .list{
    float: left;
    width: 840px;
    ul{
      li{
        @include clearfix();
      }
    }
  }
  .right-aside{
    float: right;
    width: 282px;
  }
}