.page-pagination{
  $h:29px;
  min-width: 605px;
  height: $h;
  text-align: right;
  ul{
    display: inline-block;
    margin-right: 12px;
    height: $h;
    vertical-align: middle;
    li{
      float: left;
      margin-left: 4px;
      span{
        display: block;
        float: left;
        min-width: $h;
        height: $h;
        text-align: center;
        letter-spacing: 0.1em;
        color: $gray;
      }
      a{
        display: block;
        float: left;
        min-width: $h;
        height: $h;
        line-height: $h;
        text-align: center;
        font-size: 12px;
        border: 1px solid $color-border;
        border-radius: 4px;
        color: $gray;
        &:hover{
          border:1px solid $color-hover;
          color: $color-hover;
        }
        &:active{
          border:1px solid $color-active;
          color: $color-active;
        }
        &.active{
          border:1px solid $color-hover;
          color: $color-inverse;
          background-color: $color-main;
        }
      }
    }
  }
  .select-wrap{
    display: inline-block;
    height: $h;
    line-height: $h;
    text-align: center;
    font-size: 12px;
    border: 1px solid $color-border;
    border-radius: 4px;
    color: $gray;
    margin-right: 10px;

  }
  select{
    background-color: $color-inverse;
    border: none;
  }
  .jump{
    font-size: 12px;
    color: $gray;
    input{
      width: 48px;
      height: $h;
      border: 1px solid $color-border;
      border-radius: 4px;
      text-align: center;
    }
  }
}