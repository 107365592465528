.booklist-aside{
  dt{
    font-size: 30px;
    color: $gray-dark;
    margin-bottom: 10px;
  }
  dd{
    position: relative;
    margin-bottom: 20px;
    .bottom{
      position: absolute;
      bottom:0;
      left: 0;
      width: 100%;
      height: 65px;
      padding: 5px;
      background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.70) 100%);
      color: $color-inverse;
      h2{
        margin-top: 17px;
        font-size: 16px;
      }
      p{
        font-size: 13px;
        margin-top: 4px;
      }
    }
  }
}