.vip-recharge-con {
  .txt-ad {
    font-size: 18px;
    a {
      color: #000;
    }
    img {
      margin: -4px 10px 0 0;
      vertical-align: text-bottom;
    }
  }
  .label, ul {
    margin-left: 56px;
  }
  .total {
    font-weight: bolder;
    font-size: 24px;
    color: $color-main;
    margin-left: 10px;
  }
  .recharge-body {
    border: 1px solid $color-border;
    color: #000;
    font-size: 16px;
    .head {
      height: 50px;
      line-height: 50px;
      background-color: #F8F8F9;
    }
    .top, .bottom {
      height: 60px;
      line-height: 60px;
    }
  }
  .recharge-footer {
    margin-top: 30px;
    position: relative;
    span {
      margin-right: 20px;
    }
    .tip{
      font-size: 13px;
      color: $color-main;
      line-height: 36px;
      position: absolute;
      right: 0;
      margin-right: 0;
    }
    .channel-btn {
      height: 36px;
      line-height: 36px;
      padding: 0 8px;
      background-color: $color-main;
      color: $color-inverse;
      border: 0;
      border-radius: 4px;
      outline: 0;
      font-size: 16px;
      border-radius: 4px;
    }
    a {
      color: $gray-light;
      font-size: 13px;
      &:hover {
        color: $color-hover;
      }
      &:active {
        color: $color-active;
      }
    }
  }
  .channel-list {
    @include clearfix();
    padding: 23px 0;
    li {
      display: block;
      float: left;
      margin: 5px 60px 5px 0;
      div {
        position: relative;
        border-radius: 10px;
        display: inline-block;
        width: 225px;
        height: 60px;
        line-height: 60px;
        border: 1px solid $color-border;
        &, & > img {
          vertical-align: middle;
        }
        & > img {
          margin: -6px 17px 0 17px;
          width: 40px;
          height: 40px;
        }
        i.img-icon {
          display: block;
          position: absolute;
          top: -1px;
          right: -1px;
          width: 42px;
          height: 42px;
          line-height: 42px;
          img {
            display: block;
          }
        }
      }
    }
    li:nth-child(4n){
      margin-right: 0;
    }
  }
  .amount-list {
    @include clearfix();
    padding: 23px 0;
    li {
      display: block;
      float: left;
      margin: 5px 77px 5px 0;
      div {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        width: 178px;
        height: 110px;
        border-radius: 10px;
        border: 1px solid $color-border;
        text-align: center;
        padding: 5px;
        font-size: 14px;
        color: $gray;
        p {
          margin: 7px 0;
        }
        .time {
          span {
            color: $gray-dark;
            font-weight: bold;
          }
        }
        .price {
          font-size: 30px;
          font-weight: bold;
          margin-right: 5px;
        }
        .original {
          font-size: 16px;
          text-decoration: line-through;
        }
        .describe {
          font-size: 13px;
          margin-top: 10px;
          &.two {
            margin: -6px;
          }
        }
        .img-icon {
          position: absolute;
          top: -13px;
          right: -19px;
          width: 35px;
          height: 25px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .channel-list li div:before {
    top:22px;
    left: -35px;
  }
  .amount-list li div:before {
    top:45px;
    left: -35px;
  }
  .channel-list li div:after {
    top:27px;
    left: -30px;
  }
  .amount-list li div:after {
    top:50px;
    left: -30px;
  }
  li {
    input {
      display: none;
    }
    label{
      display: table;
    }
    div {
      position: relative;
      display: table-cell;
      vertical-align: middle;
      &:before,&:after {
        position: absolute;
        content: ' ';
        float: left;
        width: 14px;
        height: 14px;
        border: 1px solid $color-border;
        border-radius: 8px;
      }
      &:after{
        width: 6px;
        height: 6px;
        border: 0;
        border-radius: 3px;
        background-color: $color-main;
        display: none;
      }
    }
    input:checked + div,div.active {
      border: 1px solid $color-main;
      &:before {
        border: 1px solid $color-main;
      }
      &:after {
        display: block;
      }
    }
  }

}