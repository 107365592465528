.vip-sub-head{
  background-color: #101010;
  .head{
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    li{
      float: left;
      margin-left: 40px;
      &:first-child{
        margin-left: 0;
      }
    }
    a{
      color: #D7D7D7;
      &:hover{
        color: $color-hover;
      }
      &:active{
        color: $color-active;
      }
    }
  }

}