.vip-box-title {
  font-size: 30px;
  .head-pagination {
    float: right;
    margin-top: 22px;
    span {
      display: block;
      float: left;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-left: 8px;
      background-color: $color-border;
      &.swiper-active-switch {
        width: 23px;
        background-color: $color-main;
      }
    }
  }
}
.vip-succeed{
  padding-top:168px;
  color: $gray-light;
  h1{
    color: $gray-dark;
    font-size: 30px;
    margin: 30px 0 15px 0;
  }
  .mb20{
    font-size: 20px;
    color: #999999;
    line-height: 28px;
  }
  .button{
    margin: 15px 10px;
    display: inline-block;
    width: 136px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    text-align: center;
    border: 1px solid $color-border;
    border-radius: 3px;
    color: $gray-light;
    &:hover{
      color: $color-hover;
      border: 1px solid $color-hover;
      color: $color-hover;
    }
    &:active{
      color: $color-active;
      border: 1px solid $color-active;
      color: $color-active;
    }
  }

  .red-button{
    margin: 15px 10px;
    display: inline-block;
    width: 136px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    text-align: center;
    border: 1px solid $color-hover;
    border-radius: 3px;
    color: $color-hover;
    &:hover{
      color: $color-hover;
    }
    &:active{
      color: $color-active;
    }
  }

  .redbg-button{
    margin: 15px 10px;
    background-color: $color-hover;
    display: inline-block;
    width: 136px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    text-align: center;
    border: 1px solid $color-hover;
    border-radius: 3px;
    color: #fff;
    &:hover{
      color: #fff;
    }
    &:active{
      color: #fff;
    }
  }
}