@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin hide-text() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin text-hide() {
  @include hide-text;
}

@mixin a-link(){
  color: $color-main;
  &:hover{
    color: $color-hover;
  }
  &:active{
    color: $color-active;
  }
}

@mixin text-hide{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}