
.full-class{padding-bottom: 0px;padding-top: 15px;overflow: hidden;margin-bottom:-5px;}
.full-class li{float: left;width: 48px;}
.full-class li a{font-size: 14px;color: #252525;}
.full-class li a:hover{color: #FD113A;}
.full-class-btn{font-size: 16px;color: #252525;float: right;background-image: url(../images/sd/header-class.png);background-repeat: no-repeat;padding-left: 25px;}

.index-banner{margin-top: 20px;}
.index-banner .pos-1{width: 510px;height: 380px;float: left;position: relative;overflow: hidden;}
.index-banner .pos-2{width: 335px;height: 185px;float: left;margin-left: 10px;position: relative;overflow: hidden;}
.index-banner .pos-3{width: 220px;height: 185px;float: left;margin-left: 10px;margin-top: 10px;position: relative;overflow: hidden;}
.index-banner-bottom{position: absolute;width: 100%;height: 86px;bottom: 0;left: 0;background-image: url(../images/sd/banner-bg.png);}
.index-banner-bottom .title{padding-left: 10px;font-size: 20px;color: #FFFFFF;font-weight: bold;position: absolute;bottom: 30px;}
.index-banner-bottom .subtitle{padding-left: 10px;font-size: 14px;color: #FFFFFF;position: absolute;bottom: 10px;}
.index-banner-control{position: absolute;bottom: 10px;right: 10px;}
.index-banner-control a{height: 10px;width: 10px;background-color: #D9D9D9;border-radius: 5px;display: block;float: left;margin-left: 8px;}
.index-banner-control a.active{width: 23px;background-color: #FD113A;}

.index-title{margin-top: 40px;height: 42px;}
.index-title h2{font-size: 30px;color: #252525;float: left;margin-left: 20px;line-height: 42px;}
.index-title-logo{float: left;vertical-align: top;position: relative;top: 5px;}
.index-title-select{margin-left: 30px;overflow: hidden;float: left;}
.index-title-select li{float: left;margin: 0 20px;}
.index-title-select li a{font-size: 16px;color: #252525;line-height: 42px;font-weight: bold;}
.index-title-select li a.active{color: #FD113A;}
.index-title-select li a.active:after{background-color: #FD113A;height: 4px;border-radius: 2px;content: "";display: block;width: 80%;margin: 0 auto;margin-top: -8px;}
.index-title-more{float: right;padding-right: 18px;background-image: url(../images/sd/index-title-more.png);background-repeat: no-repeat;background-position: 38px 15px;font-size: 14px;color: #252525;line-height: 42px;}

.index-rank-logo {
  font-size: 18px;
  color: #666666;
  margin-left: 12px;
  i{
    font-size: 1.1em;
    vertical-align: top;
  }
}

.index-title .right{float: right;}

.index-original{margin-top: 10px;}
.index-original .container{height: 460px;overflow: hidden;}
.index-original-list{overflow: hidden;}
.index-original-list li{float: left;overflow: hidden;width: 400px;margin: 3px 0;}
.index-original-list li .cover{width: 167px;height: 220px;position: relative;overflow: hidden;float: left;}
.index-original-list li .cover span{position: absolute;top: 0;right: 0;font-size: 13px;color: #FFFFFF;background: #FF5A00;padding: 2px 3px;}
.index-original-list li .info{float: left;padding-left: 15px;width: 216px;position: relative;height: 220px;}
.index-original-list li .info .title{font-size: 18px;color: #252525;font-weight: bold;margin-top: 18px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.index-original-list li .info .title a{font-size: 18px;color: #252525;font-weight: bold;}
.index-original-list li .info .title a:hover{color: #fd113a;}
.index-original-list li .info .star{margin-top: 3px;}
.index-original-list li .info .star span{width: 12px;height: 11px;background-image: url(../images/sd/index-star.png);background-repeat: no-repeat;display: inline-block;}
.index-original-list li .info .star span.active{background-image: url(../images/sd/index-star-active.png);background-repeat: no-repeat;}

.index-original-list li .info .subtitle {
  font-size: 13px;
  color: #FD113A;
  margin-top: 55px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.index-original-list li .info .subtitle span.label {
  color: #252525;
  margin-left: 10px;
}
.index-original-list li .info .subtitle span {
  color: #FD113A;
  margin-left: 10px;
}

.index-original-list li .info .subtitle span a{color: #FD113A;}
.index-original-list li .info .desc{font-size: 13px;color: #666666;margin-top: 5px;line-height: 18px;}
.index-original-list li .info .tag{position: absolute;bottom: 20px;}

.index-original-list li .info .tag span {
  font-size: 13px;
  color: #666666;
  border: 1px dashed #D9D9D9;
  border-radius: 3px;
  padding: 3px 6px;
  margin-right: 5px;
}

.index-original-control{text-align: center;font-size: 0;padding: 10px 0;}
.index-original-control a{height: 10px;width: 10px;background-color: #D9D9D9;border-radius: 5px;display: inline-block;margin-left: 8px;}
.index-original-control a.active{width: 23px;background-color: #FD113A;}
.index-original-arrow {position: relative;width: 1200px;margin: 0 auto;}
.index-original-arrow .left{height: 450px;width: 40px;display: block;position: absolute;left: -40px;top: -485px;text-align: center;padding-top: 200px;}
.index-original-arrow .left:hover{background: #F8F8F9;}
.index-original-arrow .right{height: 450px;width: 40px;display: block;position: absolute;right: -40px;top: -485px;text-align: center;padding-top: 200px;}
.index-original-arrow .right:hover{background: #F8F8F9;}

.index-rank{margin-top: 40px;width: 400px;float: left;padding-right: 20px;}
.index-rank-title{font-size: 20px;color: #252525;font-weight: bold;}
.index-rank-title .more{float: right;padding-right: 18px;background-image: url(../images/sd/index-title-more.png);background-repeat: no-repeat;background-position: 38px 7px;font-size: 14px;color: #252525;line-height: 26px;}
.index-rank-list{margin-top: 20px;}
.index-rank-list li{overflow: hidden;border-bottom: 1px dashed #EDEDED;margin-bottom: 5px;}
.index-rank-list li .cover{width: 80px;height: 50px;overflow: hidden;float: left;}
.index-rank-list li .cover img{width: 80px;}
.index-rank-list li .sign{width: 50px;text-align: center;float: left;}
.index-rank-list li .sign span{background-image: url(../images/sd/index-rank-0.png);background-repeat: no-repeat;height: 22px;width: 22px;font-size: 13px;color: #666666;display: inline-block;border-radius: 10px;line-height: 22px;text-align: center;vertical-align: top;margin-top: 2px;}
.index-rank-list li .sign span.top-1{background-image: url(../images/sd/index-rank-1.png);background-repeat: no-repeat;color: #fff;}
.index-rank-list li .sign span.top-2{background-image: url(../images/sd/index-rank-2.png);background-repeat: no-repeat;color: #fff;}
.index-rank-list li .sign span.top-3{background-image: url(../images/sd/index-rank-3.png);background-repeat: no-repeat;color: #fff;}
.index-rank-list li .info{float: left;}
.index-rank-list li .info .title{font-size: 14px;color: #252525;font-weight: bold;margin-top: 3px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 240px;}
.index-rank-list li .info .title a{font-size: 14px;color: #252525;font-weight: bold;}
.index-rank-list li .info .title a:hover{color: #fd113a;}
.index-rank-list li .info .subtitle{font-size: 13px;color: #666666;margin-top: 6px;}

.index-ad{width: 1205px;margin: 0 auto;overflow: hidden;margin-top: 40px;}
.index-ad-1{float: left;}
.index-ad-2{float: left;margin-left: 5px;}

.index-manga{margin-top: 20px;}
.index-manga-list{overflow: hidden;}
.index-manga-list li{float: left;margin-left: 4px;}
.index-manga-list li .cover{width: 167px;height: 220px;overflow: hidden;position: relative;}
.index-manga-list li .cover .img{width: 167px;}
.index-manga-list li .cover .tag{position: absolute;top: 0;right: 0;}
.index-manga-list li .title{font-size: 14px;color: #252525;margin-top: 8px;font-weight: bold;}
.index-manga-list li .subtitle{font-size: 13px;color: #666666;margin-top: 5px;}
.index-manga-list li .new{font-size: 13px;color: #252525;margin-top: 2px;}
.index-manga-list li .new span{font-size: 13px;color: #FD113A;margin-left: 5px;}

.index-manga-detail{margin-top: 20px;}
.index-manga-detail .detail{width: 500px;float: left;}
.index-manga-detail .detail-list{overflow: hidden;}
.index-manga-detail .detail-list li{float: left;width: 210px;}
.index-manga-detail .detail-list li .img{width: 100%;margin-top: 25px;}
.index-manga-detail .detail-list li.active{width: 80px;}
.index-manga-detail .detail-list li.active .img{width: 250px;height: 330px;padding: 10px;position: relative;left: -75px;margin-top: 0;background-color: #fff;box-shadow: 0 0 10px #ccc;}
.index-manga-detail .detail .title{overflow: hidden;margin-top: 25px;}
.index-manga-detail .detail .title span{font-size: 20px;color: #252525;float: left;font-weight: bold;}
.index-manga-detail .detail .star{margin-top: 8px;float: left;margin-left: 50px;}
.index-manga-detail .detail .star span{width: 12px;height: 11px;background-image: url(../images/sd/index-star.png);background-repeat: no-repeat;display: inline-block;}
.index-manga-detail .detail .star span.active{background-image: url(../images/sd/index-star-active.png);background-repeat: no-repeat;}
.index-manga-detail .detail .btn-read{background: #FD113A;border-radius: 3px;height: 36px;width: 100px;line-height: 36px;text-align: center;font-size: 16px;color: #FFFFFF;display: block;float: right;}
.index-manga-detail .detail .subtitle{font-size: 13px;color: #666666;line-height: 20px;}
.index-manga-detail .detail .new{font-size: 13px;color: #252525;line-height: 24px;}
.index-manga-detail .detail .new span{font-size: 13px;color: #FD113A;margin-left: 5px;}
.index-manga-detail .detail .tag{margin-top: 5px;padding: 5px 0;padding-bottom: 10px;}
.index-manga-detail .detail .tag span{font-size: 13px;color: #666666;border: 1px dashed #D9D9D9;border-radius: 3px;padding: 2px 5px;}

.index-manga-detail .index-manga-list{float: left;width: 684px;margin-left: 16px;}
.index-manga-detail .index-manga-list li{margin-bottom: 30px;}

.index-block{margin-top: 10px;}
.index-block .index-block-list{overflow: hidden;}
.index-block .index-block-list img{float: left;margin: 0 2px;}



.view-comment{overflow: hidden;margin-bottom: 50px;}
.view-comment .container{padding: 0 35px;}
.view-comment-main{width: 860px;background: #666666;float: left;}
.view-comment-main .top{height: 68px;line-height: 68px;border-bottom: 1px solid #999999;}
.view-comment-main .top span{font-size: 16px;color: #FFFFFF;margin-left: 17px;}
.view-comment-main .top span.s{font-size: 13px;color: #FFFFFF;margin-left: 10px;padding-right: 20px;border-right: 1px solid #fff;}
.view-comment-main .top .page{float: right;margin-right: 22px;}
.view-comment-main .top .page .block,.view-comment-main .top .page a,.view-comment-main .top .page span{font-family: monospace;background: #252525;border-radius: 4px;font-size: 12px;color: #FFFFFF;padding: 0 10px;margin: 0 5px;display: inline-block;height: 28px;line-height: 28px;}
.view-comment-main .top .page .block.active,.view-comment-main .top .page span.current{background: #FD113A;}
.view-comment-main .list{overflow: hidden;}
.view-comment-main .list li{float: left;overflow: hidden;padding-top: 22px;}
.view-comment-main .list .postlist>li:first-child{padding-top: 0;}
.view-comment-main .list li.solid{border-bottom: 1px solid #999999;padding-bottom: 25px;}
.view-comment-main .list li.dashed .info{border-bottom: 1px dashed #999999;padding-bottom: 25px;}
.view-comment-main .list li .cover{width: 50px;margin-left: 20px;float: left;}
.view-comment-main .list li .cover img{width: 50px;height: 50px;border-radius: 25px;}
.view-comment-main .list li .info{width: 750px;float: left;margin-left: 10px;}
.view-comment-main .list li .info .title{font-size: 13px;color: #fff;margin-top: 10px;font-weight: bold;}
.view-comment-main .list li .info .title .re{margin-left: 10px;color: #666;font-weight: normal;}
.view-comment-main .list li .info .title .nick{margin-left: 10px;font-weight: normal;}
.view-comment-main .list li .info .title .vip{font-weight: normal;font-size: 12px;color: #FF5A00;border: 1px solid #FF5A00;border-radius: 2px;background-color: #fff;padding: 0 3px;margin-left: 10px;}
.view-comment-main .list li .info .title .year{font-weight: normal;font-size: 12px;color: #FD113A;border: 1px solid #FD113A;border-radius: 2px;background-color: #fff;padding: 0 3px;margin-left: 10px;}
.view-comment-main .list li .info .content{font-size: 13px;color: #D7D7D7;margin-top: 15px;line-height: 18px;}
.view-comment-main .list li .info .bottom{font-size: 13px;color: #D7D7D7;margin-top: 30px;}
.view-comment-main .list li .info .bottom .right{float: right;}
.view-comment-main .list li .info .bottom .right .zan{font-size: 13px;color: #D7D7D7;padding-left: 20px;background-image: url(../images/sd/view-logo-3.png);background-repeat: no-repeat;background-position: 0px 0px;}
.view-comment-main .list li .info .bottom .right .zan.active{background-image: url(../images/sd/view-logo-3-a.png);}
.view-comment-main .list li .info .bottom .right .comment{font-size: 13px;color: #D7D7D7;padding-left: 20px;background-image: url(../images/sd/view-logo-4.png);background-repeat: no-repeat;background-position: 0px 1px;margin-left: 30px;}
.view-comment-main .list li .info textarea{width: 710px;border: 0;outline: none;margin-left: 10px;height: 120px;padding: 10px;resize: none;}
.view-comment-main .list li .info .comment-item{padding-top: 30px;font-size: 13px;color: #D7D7D7;padding-left: 10px;overflow: hidden;}
.view-comment-main .list li .info .comment-item .code{font-size: 13px;color: #999999;}
.view-comment-main .list li .info .comment-item .code input{padding: 0 10px;margin-top: -12px;width: 100px;outline: none;border: 1px solid #D9D9D9;line-height: 33px;border-radius: 3px;}
.view-comment-main .list li .info .comment-item .code img{vertical-align: top;position: relative;top: -8px;left: 5px;}
.view-comment-main .list li .info .comment-item .logo-1{background-image: url(../images/sd/view-logo-2.png);background-repeat: no-repeat;background-position: 0px 0px;padding-left: 20px;color: #D7D7D7;}
.view-comment-main .list li .info .comment-item .logo-2{background-image: url(../images/sd/view-logo-1.png);background-repeat: no-repeat;background-position: 0px 0px;padding-left: 20px;color: #D7D7D7;margin-left: 10px;margin-right: 10px;}
.view-comment-main .list li .info .comment-item .right{float: right;}
.view-comment-main .list li .info .comment-item .right .red{color: #FD113A;}
.view-comment-main .list li .info .comment-item .right .btn{background: #FD113A;border-radius: 3px;font-size: 16px;color: #FFFFFF;height: 36px;line-height: 36px;width: 100px;text-align: center;display: inline-block;margin-left: 20px;vertical-align: top;position: relative;top: -8px;}
.view-comment-main .child-list{overflow: hidden;background: #999999;border-radius: 10px;margin-top: 25px;}
.view-comment-main .child-list li .info{width: 640px;}
.view-comment-main .child-list li.open{text-align: center;width: 100%;padding: 0;}
.view-comment-main .child-list li.open a{font-size: 13px;text-decoration: underline;color: #999999;padding-top: 24px;display: block;}
.view-comment-main .child-list li.open:last-child a{padding: 24px;}
.view-comment-main .child-list li .info textarea{margin-left: 0;width: 620px;margin-top: 10px;background: #D7D7D7;height: 80px;}
.view-comment-main .bottom-bar{background-color: #272727;overflow: hidden;padding-top: 30px;padding-bottom: 30px;}
.view-comment-main .bottom-page{float: right;margin-right: 30px;}
.view-comment-main .bottom-page .block,.view-comment-main .bottom-page a,.view-comment-main .bottom-page span{font-family: monospace;background: #999;border-radius: 4px;font-size: 12px;color: #FFFFFF;padding: 0 10px;margin: 0 5px;display: inline-block;height: 28px;line-height: 28px;}
.view-comment-main .bottom-page .block.active,.view-comment-main .bottom-page span.current{background: #FD113A;}

body.white .view-comment-main .list li .info .bottom .right .zan.active{background-image: url(../images/sd/view-logo-3-w-a.png);color: #fd113a;}
body.white .view-comment-main .list li .info .comment-item .logo-1{background-image: url(../images/sd/view-logo-2.png);background-repeat: no-repeat;background-position: 0px 0px;padding-left: 20px;color: #999;}
body.white .view-comment-main .list li .info .comment-item .logo-2{background-image: url(../images/sd/view-logo-1.png);background-repeat: no-repeat;background-position: 0px 0px;padding-left: 20px;color: #999;margin-left: 10px;}
body.white .view-comment-main .list li .info .comment-item{font-size: 13px;color: #999;padding-left: 10px;}
body.white .view-comment-main .list li .info .content{font-size: 13px;color: #666;margin-top: 15px;line-height:22px;}
body.white .view-comment-main .list li .info .bottom{font-size: 13px;color: #999;margin-top: 30px;}
body.white .view-comment-main .list li .info .bottom .right .zan{font-size: 13px;color: #999;padding-left: 20px;background-image: url(../images/sd/view-logo-3.png);background-repeat: no-repeat;background-position: 0px 0px;}
body.white .view-comment-main .list li .info .bottom .right .comment{font-size: 13px;color: #999;padding-left: 20px;background-image: url(../images/sd/view-logo-4.png);background-repeat: no-repeat;background-position: 0px 1px;margin-left: 30px;}
body.white .view-comment-main .list li.solid{border-bottom: 1px solid #ededed;padding-bottom: 25px;}
body.white .view-comment-main .list li.dashed .info{border-bottom: 1px dashed #ededed;padding-bottom: 25px;}
body.white .view-comment-main .top .page .block,body.white .view-comment-main .top .page a,body.white .view-comment-main .top .page span{background-color: #fff;color: #666;border: 1px solid #d9d9d9;cursor: pointer;}
body.white .view-comment-main .top .page a:hover{border: 1px solid #FD113A;color: #FD113A;}
body.white .view-comment-main .top .page .block.active,body.white .view-comment-main .top .page span.current{background: #FD113A;color: #fff;border: 1px solid #FD113A;}
body.white .view-comment-main{width: 860px;border: 1px solid #d9d9d9;}
body.white .view-comment-main .top{border-bottom: 1px solid #ededed;}
body.white .view-comment-main .top span{color: #252525;}
body.white .view-comment-main .top span.active{color: #FD113A;}
body.white .view-comment-main .top span.s{color: #999;border-right: 1px solid #252525;}
body.white .view-comment-main{background: #fff;}
body.white .view-comment-main .list li .info .title{color: #252525;}
body.white .view-comment-main .list li .info textarea{width: 740px;border: 1px solid #d9d9d9;}
body.white .view-comment-main .child-list{background: #f8f8f8;}
body.white .view-comment-main .child-list li .info textarea{background: #fff;width: 638px;border: 1px solid #d9d9d9;}
body.white .view-comment-main .bottom-bar{background-color: #fff;border-top: 1px solid #d9d9d9;margin: -1px;}
body.white .view-comment-main .bottom-page .block,body.white .view-comment-main .bottom-page a,body.white .view-comment-main .bottom-page span{background-color: #fff;color: #666;border: 1px solid #d9d9d9;cursor: pointer;}
body.white .view-comment-main .bottom-page a:hover{border: 1px solid #FD113A;color: #FD113A;}
body.white .view-comment-main .bottom-page .block.active,body.white .view-comment-main .bottom-page span.current{background: #FD113A;color: #fff;border: 1px solid #FD113A;}

.view-comment .index-manga{padding-bottom: 30px;}
.view-comment .index-manga .col7{margin: 0;}
.view-comment .index-title h2{font-size: 20px;color: #252525;margin-left: 0px;}
.view-comment .detail-ad{width: 860px;overflow: hidden;height: 90px;margin-top: 40px;}
.view-comment .detail-ad img{height: 90px;}
.view-comment .detail-list-title{margin-top: 10px;}
.view-comment .detail-list-title .block{font-size: 16px;color: #252525;padding-right: 20px;margin-right: 20px;border-right: 1px solid #252525;}
.view-comment .detail-list-title .block span{font-size: 13px;}
.view-comment .detail-list-title .block.active{color: #FD113A;}
.view-comment .detail-list-title .s{font-size: 13px;color: #252525;}
.view-comment .detail-list-title .s .red{color: #FD113A;margin-left: 10px;margin-right: 10px;}
.view-comment .detail-list-title .order{font-size: 14px;color: #999999;padding-left: 26px;background-image: url(../images/sd/detail-order.png);background-repeat: no-repeat;float: right;line-height: 21px;}
.view-comment .detail-list-title .order.inverted{background-image: url(../images/sd/detail-order-inverted.png);}
.view-comment .left-bar{width: 860px;float: left;}
.view-comment .detail-list-subtitle{text-align: right;margin-top: 25px;}
.view-comment .detail-list-subtitle .red{font-size: 13px;color: #FD113A; margin-right: 10px;}
.view-comment .detail-list-subtitle .red span{color: #fff;background-color: #FD113A;border-radius: 2px;padding: 0 2px;margin-right: 5px;}
.view-comment .detail-list-subtitle .date{font-size: 13px;color: #666666;margin-left: 5px;}

.view-comment .view-win-list {
  overflow: hidden;
  margin: 5px -10px 0 -10px;
  width: 880px;
  padding-left: 5px;
}

.view-comment .view-win-list li {
  float: left;
  height: 36px;
  line-height: 36px;
  width: 271.5px;
  background: #F6F6F6;
  margin-top: 20px;
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 10px;
  a{
    display: block;
    width: 100%;
    height: 100%;
  }
  &:hover{
    background: #ffeef1;
    a{
      color: $color-active;
    }
  }
  a.active{
    color: $color-main;
  }
}

.view-comment .view-win-list li a {
  font-size: 14px;
  color: #252525;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 50px;
  position: relative;
}

.view-comment .view-win-list li a span{
  font-size: 13px;
}

.view-comment .view-win-list .view-lock{background-image: url(../images/sd/view-lock.png);background-repeat: no-repeat;float: right;display: inline-block;height: 36px;width: 30px;background-position: 1px 8px;position: absolute;top: 0;right: 0;}
.view-comment .view-win-list .view-time{background-image: url(../images/sd/view-time.png);background-repeat: no-repeat;float: right;display: inline-block;height: 36px;width: 30px;background-position: 1px 10px;position: absolute;top: 0;right: 0;}
.view-comment .view-win-list .view-free{background-image: url(../images/sd/view-free.png);background-repeat: no-repeat;float: right;display: inline-block;height: 36px;width: 30px;background-position: 1px 0px;position: absolute;top: 0;right: 0;}
.view-comment .view-win-list .view-free-yellow{background-image: url(../images/sd/view-free-yellow.png);background-repeat: no-repeat;float: right;display: inline-block;height: 36px;width: 30px;background-position: 1px 0px;position: absolute;top: 0;right: 0;}
.view-comment .view-win-list .view-discount{background-image: url(../images/sd/view-discount.png);background-repeat: no-repeat;float: right;display: inline-block;height: 36px;width: 30px;background-position: 1px 0px;position: absolute;top: 0;right: 0;}
.view-comment .view-win-list .view-discount-red{background-image: url(../images/sd/view-discount-red.png);background-repeat: no-repeat;float: right;display: inline-block;height: 36px;width: 30px;background-position: 1px 0px;position: absolute;top: 0;right: 0;}
.view-comment .detail-more{display: block;width: 105px;padding-right: 25px;font-size: 13px;color: #999999;margin: 20px auto;background-image: url(../images/sd/detail-more.png);background-repeat: no-repeat;background-position: 85px 4px;}

.view-comment .view-comment-sub{float: right;width: 240px;}
.view-comment .view-comment-sub .avatar-bar{margin-top: 10px;background: #FFFFFF;border: 1px solid #D9D9D9;text-align: center;}
.view-comment .view-comment-sub .avatar-bar .avatar{width: 80px;height: 80px;margin-top: 30px;border-radius: 50%;}
.view-comment .view-comment-sub .avatar-bar .title{font-size: 20px;color: #252525;margin-top: 10px;}
.view-comment .view-comment-sub .avatar-bar .subtitle{font-size: 13px;color: #252525;margin-top: 5px;}
.view-comment .view-comment-sub .avatar-bar .tip{font-size: 13px;color: #252525;margin-top: 5px;}
.view-comment .view-comment-sub .avatar-bar .tip .red{color: #FD113A;}
.view-comment .view-comment-sub .avatar-bar .bottom{margin-top: 25px;padding-bottom: 30px;}
.view-comment .view-comment-sub .avatar-bar .bottom img{margin: 0 15px;vertical-align: top;}

.view-comment .view-comment-sub .sub-bar{height: 380px;background: #FFFFFF;border: 1px solid #D9D9D9;margin-top: 10px;}
.view-comment .view-comment-sub .sub-bar .title{font-size: 18px;color: #252525;height: 42px;line-height: 42px;padding: 0 10px;padding-left: 20px;font-weight: bold;}
.view-comment .view-comment-sub .sub-bar .title .more{float: right;padding-right: 18px;background-image: url(../images/sd/index-title-more.png);background-repeat: no-repeat;background-position: 38px 15px;font-size: 14px;color: #252525;line-height: 42px;font-weight: normal;}
.view-comment .view-comment-sub .sub-bar .slide{position: relative;padding: 20px 40px;text-align: center;padding-bottom: 15px;height:283px;overflow: hidden;}
.view-comment .view-comment-sub .sub-bar .slide .cover{width: 130px;height: 164px;position: relative;overflow: hidden;margin: 0 auto;}
.view-comment .view-comment-sub .sub-bar .slide .cover img{width: 130px;}
.view-comment .view-comment-sub .sub-bar .slide .cover span{position: absolute;top: 0;right: 0;font-size: 14px;color: #FFFFFF;background: #FF5A00;padding: 0 5px;}
.view-comment .view-comment-sub .sub-bar .slide .subtitle{font-size: 14px;color: #252525;margin-top: 12px;font-weight: bold;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.view-comment .view-comment-sub .sub-bar .slide .tip{font-size: 12px;color: #666666;margin-top: 18px;line-height: 18px;}
.view-comment .view-comment-sub .sub-bar .slide .left-arrow{position: absolute;left: 15px;top: 100px;}
.view-comment .view-comment-sub .sub-bar .slide .right-arrow{position: absolute;right: 15px;top: 100px;}

.view-comment .view-comment-sub .sub-bar .btn-1 {
  background: #FFDB6F;
  border-radius: 3px;
  font-size: 16px;
  color: #874E12;
  height: 36px;
  line-height: 36px;
  width: 103px;
  text-align: center;
  display: inline-block;
  display: block;
  margin: 0 auto;
}
.view-comment .view-comment-sub .sub-bar .btn-1 i{
  font-size: 1.4em;
  vertical-align: middle;
  margin-top: -3px;
  margin-right:10px;
}

.view-comment .view-comment-sub .sub-bar .btn-1.active {
  color: #666;
  background: #f6f6f6;
}

.view-comment .view-comment-sub .sub-manga{background: #FFFFFF;border: 1px solid #D9D9D9;margin-top: 20px;}
.view-comment .view-comment-sub .sub-manga .top{font-size: 18px;color: #252525;height: 50px;line-height: 50px;padding-left: 20px;border-bottom: 1px solid #D8D8D8;font-weight: bold;}
.view-comment .view-comment-sub .sub-manga .top .select{float: right;margin: 15px;background-color: #EDEDED;height: 20px;line-height: 20px;border-radius: 10px;display: inline-block;vertical-align: top;}
.view-comment .view-comment-sub .sub-manga .top .select a{font-size: 13px;color: #666666;height: 20px;line-height: 20px;display: inline-block;vertical-align: top;width: 30px;text-align: center;}
.view-comment .view-comment-sub .sub-manga .top .select a.active{background: #FD113A;color: #fff;border-radius: 10px;}
.view-comment .view-comment-sub .sub-manga .list{overflow: hidden;padding: 6px 11px;}
.view-comment .view-comment-sub .sub-manga .list li{float: left;margin: 6px 0;width: 100%;border-top: 1px dashed #D9D9D9;padding-top: 12px;}
.view-comment .view-comment-sub .sub-manga .list li:first-child{border-top: none;padding-top: 0;}
.view-comment .view-comment-sub .sub-manga .list li .cover{float: left;width: 70px;height: 90px;overflow: hidden;position: relative;}
.view-comment .view-comment-sub .sub-manga .list li .cover img{width: 70px;}
.view-comment .view-comment-sub .sub-manga .list li .cover span.top1{position: absolute;font-size: 16px;color: #FFFFFF;width: 24px;height: 24px;background-color: #F9D600;border-top-right-radius: 24px;left: 0;bottom: 0;line-height: 19px;text-align: center;padding-right: 5px;padding-top: 5px;}
.view-comment .view-comment-sub .sub-manga .list li .cover span.top2{position: absolute;font-size: 16px;color: #FFFFFF;width: 24px;height: 24px;background-color: #feaf00;border-top-right-radius: 24px;left: 0;bottom: 0;line-height: 19px;text-align: center;padding-right: 5px;padding-top: 5px;}
.view-comment .view-comment-sub .sub-manga .list li .cover span.top3{position: absolute;font-size: 16px;color: #FFFFFF;width: 24px;height: 24px;background-color: #fe8f00;border-top-right-radius: 24px;left: 0;bottom: 0;line-height: 19px;text-align: center;padding-right: 5px;padding-top: 5px;}
.view-comment .view-comment-sub .sub-manga .list li .cover span{position: absolute;font-size: 16px;color: #666;width: 24px;height: 24px;background-color: #f7f7f7;border-top-right-radius: 24px;left: 0;bottom: 0;line-height: 19px;text-align: center;padding-right: 5px;padding-top: 5px;}
.view-comment .view-comment-sub .sub-manga .list li .info{padding-top: 5px;float: left;padding-left: 10px;width: 128px;position: relative;}
.view-comment .view-comment-sub .sub-manga .list li .info .title{margin-left: 0px;font-size: 14px;color: #252525;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-weight: bold;}
.view-comment .view-comment-sub .sub-manga .list li .info .title a{color: #252525;}
.view-comment .view-comment-sub .sub-manga .list li .info .subtitle{font-size: 13px;color: #252525;margin-top: 10px;}
.view-comment .view-comment-sub .sub-manga .list li .info .subtitle-new{font-size: 12px;color: #666;margin-top: 4px;line-height: 18px;}
.view-comment .view-comment-sub .sub-manga .list li .info .subtitle span{margin-right: 5px;}
.view-comment .view-comment-sub .sub-manga .list li .info .subtitle a{color: #252525;}
.view-comment .view-comment-sub .sub-manga .list li .info .tip{font-size: 13px;color: #666;float: none;position: absolute;top: 75px;left: 10px;}
.view-comment .view-comment-sub .sub-manga .list li .num{font-size: 16px;color: #666666;}
.view-comment .view-comment-sub .sub-manga .list li .title{font-size: 14px;color: #252525;margin-left: 10px;}
.view-comment .view-comment-sub .sub-manga .list li .tip{font-size: 13px;color: #999999;float: right;position: relative;top: 2px;}

.view-comment .view-detail-list{overflow: hidden;margin-top: 5px;width: 860px;padding-left: 5px;}

.view-comment .view-detail-list li {
  float: left;
  height: 100px;
  overflow: hidden;
  background: #F6F6F6;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  &:hover{
    background: #ffeef1;
    color: $color-main;
  }
}

.view-comment .view-detail-list li .cover{height: 100px;width: 172px;float: left;overflow: hidden;position: relative;}
.view-comment .view-detail-list li .cover .img{
  width: auto;
  height: 100%;
}
.view-comment .view-detail-list li .cover .tag{position: absolute;top: 0;left: 0;}
.view-comment .view-detail-list li .info{float: left;margin-left: 20px;width: 630px;position: relative;}
.view-comment .view-detail-list li .info .title{font-size: 16px;color: #252525;margin-top: 15px;}
.view-comment .view-detail-list li .info .title.active{color: $color-main;}
.view-comment .view-detail-list li:hover .info .title {
  color: $color-main;
}
.view-comment .view-detail-list li .info .title span{font-size: 13px;}
.view-comment .view-detail-list li:hover .info .title.active {
  color: $color-main;
}
.view-comment .view-detail-list li .info .subtitle{font-size: 10px;color: #FD113A;margin-top: 10px;}
.view-comment .view-detail-list li .info .subtitle .b{font-size: 16px;color: #FD113A;margin-right: 5px;}
.view-comment .view-detail-list li .info .subtitle .del{font-size: 13px;color: #999999;text-decoration: line-through;}
.view-comment .view-detail-list li .info .tip{font-size: 13px;color: #999999;margin-top: 15px;}
.view-comment .view-detail-list li .detail-lock{background-image: url(../images/sd/view-lock.png);background-repeat: no-repeat;display: inline-block;height: 19px;width: 16px;position: absolute;right: 0;top: 35px;}

.view-comment .warning-bar{margin:0 auto;margin-top: 40px;overflow: hidden;width: 600px;}
.view-comment .warning-bar img{float: left;}
.view-comment .warning-bar p{width: 530px;float: left;margin-left: 20px;font-size: 16px;color: #666666;line-height: 22px;}
.view-comment .warning-bar a{display: block;height: 36px;line-height: 36px;text-align: center;border: 1px solid #D9D9D9;border-radius: 3px;float: left;margin-left: 220px;font-size: 16px;color: #252525;margin-top: 20px;padding: 0 10px;}

.view-comment .left-bar .top{overflow: hidden;background: #FFFFFF;border: 1px solid #EDEDED;width: 860px;padding: 20px;margin-bottom: 20px;margin-top: 10px;}
.view-comment .left-bar .top .cover{width: 180px;overflow: hidden;float: left;}
.view-comment .left-bar .top .cover img{width: 180px;}
.view-comment .left-bar .top .info{float: left;width: 610px;margin-left: 20px;}
.view-comment .left-bar .top .info .title{font-size: 30px;color: #252525;}
.view-comment .left-bar .top .info .subtitle{font-size: 13px;color: #666666;margin-top: 15px;}
.view-comment .left-bar .top .info .tag{margin-top: 15px;}
.view-comment .left-bar .top .info .tag span{font-size: 13px;color: #666666;border: 1px dashed #D9D9D9;border-radius: 3px;padding: 2px 5px;}
.view-comment .left-bar .top .info .content{margin-top: 20px;font-size: 13px;color: #666666;line-height: 18px;}
.view-comment .left-bar .list{overflow: hidden;background: #FFFFFF;border: 1px solid #EDEDED;float: left;margin-top: 20px;}
.view-comment .left-bar .list li{float: left;padding-left: 20px;height: 50px;line-height: 50px;border-top: 1px solid #EDEDED;width: 100%;}
.view-comment .left-bar .list li:first-child{border-top: none;}
.view-comment .left-bar .list li .title{font-size: 16px;color: #252525;}
.view-comment .left-bar .list li .subtitle{font-size: 16px;color: #999999;}
.view-comment .left-bar .list li .btn{font-size: 16px;background: #fff;color: #FD113A;border-radius: 3px;padding: 0 10px;float: right;line-height: 36px;margin-top: 7px;margin-right: 10px;}
.view-comment .left-bar .list li .btn:hover{background: #FD113A;color: #FFFFFF;}

.index-booklist{margin-top: 20px;}
.index-booklist-list{overflow: hidden;}
.index-booklist-list li{float: left;width: 236px;margin-left: 5px;}
.index-booklist-list li:first-child{margin-left: 0;}
.index-booklist-list li .cover{width: 236px;height: 167px;overflow: hidden;}
.index-booklist-list li .cover img{width: 236px;}
.index-booklist-list li .title{margin-top: 10px;}
.index-booklist-list li .title a{font-size: 16px;color: #252525;font-weight: bold;}
.index-booklist-list li .title a:hover{color: #fd113a;}
.index-booklist-list li .subtitle{margin-top: 5px;}
.index-booklist-list li .subtitle a{font-size: 13px;color: #666666;}

.index-rank-bar{overflow: hidden;}
.index-rank-bar .left-list{width: 90px;float: left;overflow: hidden;margin-top: 15px;}
.index-rank-bar .left-list li{height: 36px;line-height: 36px;width: 90px;float: left;text-align: center;margin-top: 5px;}
.index-rank-bar .left-list li a{font-size: 13px;color: #252525;display: block;height: 36px;background: #F7F7F7;}
.index-rank-bar .left-list li.active a,.index-rank-bar .left-list li:hover a{font-size: 13px;color: #FD113A;background: #FFEEF1;}
.index-rank-bar .right-list{float: left;overflow: hidden;margin-top: 15px;width: 190px;margin-left: 32px;}
.index-rank-bar .right-list li{height: 36px;line-height: 36px;float: left;margin-top: 5px;width: 190px;}
.index-rank-bar .right-list li a {  font-size: 13px; color: #252525; background: transparent; display: block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; }

.index-rank-bar .right-list li a:hover{color: #FD113A;}
.index-rank-bar .right-list li .sign{width: 40px;text-align: left;float: left;}
.index-rank-bar .right-list li .sign span{background-image: url(../images/sd/index-rank-0.png);background-repeat: no-repeat;height: 22px;width: 22px;font-size: 13px;color: #666666;display: inline-block;border-radius: 10px;line-height: 22px;text-align: center;vertical-align: top;margin-top: 7px;}
.index-rank-bar .right-list li .sign span.top-1{background-image: url(../images/sd/index-rank-1.png);background-repeat: no-repeat;color: #fff;}
.index-rank-bar .right-list li .sign span.top-2{background-image: url(../images/sd/index-rank-2.png);background-repeat: no-repeat;color: #fff;}
.index-rank-bar .right-list li .sign span.top-3{background-image: url(../images/sd/index-rank-3.png);background-repeat: no-repeat;color: #fff;}

.index-cooperation-list{overflow: hidden;width: 1236px;}
.index-cooperation-list li{float: left;width: 167px;margin-top: 30px;margin-right: 39px;}
.index-cooperation-list li .cover{width: 167px;height: 104px;overflow: hidden;}
.index-cooperation-list li .cover img{width: 167px;}
.index-cooperation-list li .logo{width: 167px;height: 30px;text-align: center;overflow: hidden;margin-top: 20px;}

.index-friendship{margin-top: 30px;padding-top: 40px;background: #F7F7F7;}
.index-friendship .title{font-size: 16px;color: #666666;}
.index-friendship-list{overflow: hidden;}
.index-friendship-list li{float: left;width: 150px;margin-top: 14px;}
.index-friendship-list li a{font-size: 13px;color: #999999;}
.index-friendship-list li a:hover{color: #FD113A;}

.index-right-float{background-color: #fff;z-index: 9;position: fixed;left: 50%;top: 50%;width: 40px;box-shadow: 0 0 6px 0 rgba(0,0,0,0.10);margin-left: 670px;margin-top: -200px;}
.index-right-float li{float: left;height: 44px;width: 40px;text-align: center;border-top: 1px dashed #D9D9D9;}
.index-right-float li:first-child{border-top: none;}
.index-right-float li a{font-size: 13px;color: #666666;line-height: 15px;display: block;padding-top: 6px;height: 44px;}
.index-right-float li a:hover,.index-right-float li a.active{color: #fff;background: #FD113A;}
.index-right-float li a.index-right-float-top{background-image: url(../images/sd/index-top.png);background-repeat: no-repeat;background-position: center;}
.index-right-float li a.index-right-float-top:hover{background-image: url(../images/sd/index-top-a.png);}
.index-right-float li a.index-side-code{background-image: url(../images/sd/index-side-code.png);background-repeat: no-repeat;background-position: center;}
.index-right-float li a.index-side-code:hover{background-image: url(../images/sd/index-side-code-a.png);}
.index-right-float li a.index-side-code .index-float-code{background-color: #fff;position: absolute;padding: 8px;right: 42px;bottom: 0px;height: 130px;box-shadow: 0px 0px 10px #aaa;display: none;}
.index-right-float li a.index-side-code:hover .index-float-code{display: block;}
.index-right-float.bottom{top:auto;bottom: 240px;}

.mh-item .mh-item-detali p.chapter{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.mh-cover{background-size: 100% auto;}

.alertTop_1 {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -35px;
  margin-left: -120px;
  height: 50px;
  width: 210px;
  padding: 10px 15px;
  background-color: #000;
  border-radius: 5px;
  z-index: 1002;
  display: none;
  filter: alpha(opacity=90);
  -moz-opacity: 0.9;
  -khtml-opacity: 0.9;
  opacity: 0.9;
}

.alertTop_1 p {
  color: #fff;
  font-size: 18px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  height: 33px;
  width: 210px;
  *position: relative;
  *top: 10px;
  font-family: 'Microsoft YaHei';
}