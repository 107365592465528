.vip-reason {
  ul {
    @include clearfix();
    li {
      position: relative;
      display: block;
      float: left;
      height: 200px;
      border-radius: 12px;
      border: 1px solid $color-border;
      background-color: $color-inverse;
      &.active{
        border: 1px solid $color-main;
        &:after{
          position: absolute;
          bottom:-40px;
          left: 50%;
          content: '';
          display: block;
          width: 0;
          height: 0;
          margin-left: -30px;
          border-width: 30px;
          border-style: solid;
          border-color: transparent transparent $color-inverse transparent ;
        }
      }
    }
    &.col2 {
      margin: 20px -29px;
      li {
        width: 570px;
        margin: 0 29px;
        padding:40px 0 0 47px;
        img{
          float: left;
        }
        span{
          display: block;
          margin-left: 165px;
          &.title{
            color: #000;
            font-size: 40px;
            margin:15px 0 15px 165px;
          }
          &.sub{
            color: $gray;
            font-size: 26px;
            font-weight: 300;
          }
        }
      }
    }
    &.col6{
      margin: 20px -12px;
      li {
        width: 180px;
        padding-top:30px;
        margin: 0 12px;
        text-align: center;
        cursor: pointer;
        img{
          height: 59px;
          width: auto;
        }
        span{
          display: block;
          &.title{
            color: #000;
            font-size: 20px;
            margin:30px 0 15px 0;
          }
          &.sub{
            color: $gray-light;
            font-size: 13px;
          }
        }
        &.null{
          .title,.sub{
            color: #D7D7D7;
          }
        }
      }
    }
  }
}