.vip-grow-con{
  h1{
    color: $gray-dark;
    font-size: 50px;
    text-align: center;
    margin-bottom:30px;
  }
  p{
    color: $gray;
    font-size: 30px;
    text-align: center;
    margin-bottom: 45px;
  }
  .line-btn{
    .btn{
      float: none;
      display: inline-block;
      width: 180px;
      height: 60px;
      line-height: 60px;
      font-size: 20px;
      &.line{
        background-color: $color-inverse;
        border: 1px solid $color-main;
        color: $color-main;
      }
      &.disable{
        background-color: $color-inverse;
        border: 1px solid $color-border;
        color: $gray-dark;
      }
    }
    i{
      font-size: 20px;
      color: #d7d7d7;
      vertical-align: middle;
      margin: 0 10px;
    }
  }
  table{
    width: 100%;
    td{
      height: 60px;
      text-align: center;
      background-color: $color-inverse;
      border: 1px solid $color-border;
      color: $gray;
      font-size: 16px;
      &.rowspan2{
        padding: 0 40px;
        text-align: left;
      }
    }
    thead td{
      background-color: $gray;
      color:$color-inverse;
    }

  }
}