.box-con-5n1 {
  position: relative;
  height: 450px;
  .detail-tabs-container {
    position: relative;
    .detail-tabs-pane {
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      background-color: $color-inverse;
      &.active{
        display: block;
      }
      .mh-cover {
        width: 339px;
        height: 450px;
        padding-top: 0;
        float: left;
      }
      .detail{
        margin-left: 339px + 20px;
        dt,dd{
          margin-bottom: 10px;
        }
        .title{
          a{
            font-size: 20px;
            color: $gray-dark;
            &:hover{
              color: $color-hover;
            }
            &:active{
              color: $color-active;
            }
          }
        }
        .info{
          color: $gray;
          font-size: 13px;
          &>span{
            margin-right: 15px;
          }
          .new{
            color: $gray-dark;
          }
          a{
            color: $color-main;
            &:hover{
              color: $color-hover;
            }
            &:active{
              color: $color-active;
            }
          }
        }
        .label{
          height:26px;
          span{
            display: inline-block;
            height: 26px;
            line-height: 26px;
            padding: 0 4px;
            margin-right: 4px;
            font-size: 13px;
            color: $gray;
            border-radius: 3px;
            border: 1px dashed $color-border;
          }
        }
        .desc{
          color:$gray;
          font-size: 13px;
          line-height: 1.5em;
          max-height: 3em;
          overflow: hidden;
        }
      }
    }
  }
  .detail-tabs {
    position: absolute;
    bottom: 0;
    right: 0;
    li{
      position: relative;
      display: block;
      float: left;
      width: 176.52px;
      margin:0 -2.5px;
      border: 5px solid $color-inverse;
      &.active{
        z-index:2;
        border: 5px solid $color-main;
        &:before{
          content: ' ';
          position: absolute;
          top:-27px;
          left: 50%;
          width: 0;
          height: 0;
          border-style:solid ;
          border-width: 12px;
          border-color:  transparent transparent $color-main transparent;
          margin-left: -12px;
        }
      }
    }
  }
}