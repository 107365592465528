.mh-cover{
  position: relative;
  display: block;
  width: 100%;
  padding-top: 131.736527%;
  background-size: cover;
  &.auto{
    width: 100%;
    padding-top: 0;
    height: 100%;
  }
  &.tip{
    width: 226px;
    padding-top: 0;
    height: 160px;
  }
  .tag {
    position:absolute;
    top:0;
    right: 0;
    padding:3px 2px 3px 4px;
    background-color: #FF5A00;
    background-image: linear-gradient(-217deg, #FF5A00 0%, #FD113A 100%);
    font-size: 12px;
    letter-spacing: 0.1em;
    color:$color-inverse;
  }
  .num{
    position: absolute;
    bottom: 0;
    left: 0;
    width:40px;
    height: 40px;
    padding: 18px 5px 0 0;
    border-radius: 0 40px 0 0;
    background-color: #F9D600;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  .read-section{
    position:absolute;
    bottom:10px;
    right:10px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    padding: 0 10px;
    color: $color-inverse;
    background-color: rgba(247, 186, 42, 0.9);
    font-size: 13px;
  }
  .edit-state{
    display: none;
    position:absolute;
    top:0px;
    right: 0px;
    width: 100%;
    height: 100%;
    color: $color-inverse;
    background-color: rgba(#000,0.7);
    z-index: 1;
    font-size: 30px;
    padding: 10px;
    text-align: right;
    &.lock{
      text-align: center;
      padding-top:90px;
    }
    &:hover{
      color: $color-hover;
    }
    &:active,&.active{
      color: $color-active;
    }
 }
}


