.vip-banner-simple {
  background-size: cover;
  background-position: center center;
  text-align: center;
  .banner-simple-wrap{
    position: relative;
    height: 300px;
  }
  .title {
    margin-top: 100px;
    font-size: 40px;
    font-weight: normal;
    color: $color-vip;
    i{
      margin: 0 10px;
      vertical-align: middle;
    }
  }
  .sub{
    font-size: 20px;
    font-weight: 300;
    color: $color-vip;
    margin:30px auto 20px auto;
  }
  .open-vip-btn {
    display: inline-block;
    width: 190px;
    height: 40px;
    line-height: 40px;
    background-image: linear-gradient(-90deg, #EFDCB3 3%, #DAB77C 100%);
    border-radius: 20px;
    color: #222222;
    text-align: center;
    i {
      vertical-align: middle;
      margin: -4px 20px 0 0;
    }
  }
  .grow-desc{
    position: absolute;
    top:150px;
    left: 77px;
    text-align: left;
    h1{
      font-size: 60px;
      color: #FFB8B5;
    }
    p{
      margin-top: 10px;
      font-size: 20px;
      color: #FFB8B5;
    }
  }
  .grow-chart{
    position: absolute;
    top: 0;
    right: 0;
    width: 600px;
    height: 300px;
    table{
      width: 100%;
      height: 100%;
      td{
        vertical-align: bottom;
        div{
          position: relative;
          background: #FFA3A0;
          span{
            position: absolute;
            display: block;
            opacity: 0.6;
            &.rand-val{
              bottom: 0px;
              width: 100%;
              height: 30px;
              line-height: 28px;
              text-align: center;
              font-size: 20px;
              font-weight: 300;
              color: #fff;
            }
            &.rand-line{
              position: absolute;
              left: 50%;
              width:1px;
              top:-40px;
              height: 40px;
              border-right: 1px dashed $color-inverse;
              &:before{
                display: block;
                content: ' ';
                width: 8px;
                height: 8px;
                margin:-8px 0 0 -4px;
                border-radius: 4px;
                background-color: $color-inverse;
              }
            }
            &.rand-name{
              position: absolute;
              top:-72px;
              right: 50%;
              margin:0 -7px 0 0;
              color: $color-inverse;
              font-size: 20px;
              letter-spacing: 0.1em;
            }
            &.rand-arrow{
              position: absolute;
              top:-80px;
              right: 2px;
              background-image: url('../images/icon-chart-arrow.png');
              width: 11px;
              height: 12px;

            }
            &.val-title{
              top:-60px;
              left: 30px;
              color: $color-inverse;
              font-size: 14px;
            }
            &.rand-title{
              top:-20px;
              left: 30px;
              color: $color-inverse;
              font-size: 14px;
            }
            &.rand-dan{
              top:-50px;
              right: 0;
              color: $color-inverse;
              font-size: 14px;
              display: block;
              width: 8px;
              height: 8px;
              margin:-8px 0 0 -4px;
              border-radius: 4px;
              background-color: $color-inverse;
            }
          }
        }
        &:nth-child(1) div{
          height: 0;
        }
        &:nth-child(2) div{
          background-image: linear-gradient(-180deg, #E32C52 0%, #FFA8B3 100%);
          box-shadow: -4px 0 7px 0 rgba(0,0,0,0.20);
          height: 30px;
        }
        &:nth-child(3) div{
          background-image: linear-gradient(-180deg, #E32C52 0%, #FFA8B3 100%);
          box-shadow: -4px 0 7px 0 rgba(0,0,0,0.20);
          height: 50px;
        }
        &:nth-child(4) div{
          background-image: linear-gradient(-180deg, #C0103D 0%, #FFAFB2 100%);
          box-shadow: -4px 0 6px 0 rgba(0,0,0,0.20);
          height: 80px;
        }
        &:nth-child(5) div{
          background-image: linear-gradient(-180deg, #B80E32 0%, #FFA5A1 100%);
          box-shadow: -4px 0 6px 0 rgba(0,0,0,0.20);
          height: 110px;
        }
        &:nth-child(6) div{
          background-image: linear-gradient(-180deg, #AB0528 0%, #FF6286 98%);
          box-shadow: -4px 0 6px 0 rgba(0,0,0,0.20);
          height: 160px;
        }
      }
    }
  }
}