.account-box {
  height: 420px;
  margin: 55px 0 45px 0;
    .head {
    text-align: center;
    font-size: 30px;
    color: $gray-dark;
  }
  .left, .right {
    position: relative;
    width: 50%;
    height: 100%;
  }
  .left {
    float: left;
  }
  .right {
    position: relative;
    float: right;
    padding: 30px 0 0 100px;
    &:before{
      content: ' ';
      position: absolute;
      left: 0;
      display: block;
      width: 1px;
      height: 322px;
      background-color: $color-border;
    }
  }
  .qr-wrap {
    @include clearfix();
    background-image: url('../images/account-bg.png');
    background-size: 100% 100%;
    width: 198px;
    height: 385px;
    margin: 10px 0 0 190px;
    & > div {
      @include clearfix();
      margin: 124px 0 0 33px;
      width: 350px;
      height: 129px;
      .qrcode {
        display: block;
        float: left;
        width: 129px;
        height: 129px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        margin: 40px 0 10px 180px;
        font-size: 15px;
        color: #000;
        font-weight: bold;
      }
      .sub {
        margin-left: 180px;
        font-size: 13px;
        color: $color-main;
      }
    }
  }


  &.reset-password{
      margin: 140px 0 0 126px;
    .form-wrap{
      width: 400px;
      margin: 30px auto 0 auto;
    }
  }
  &.text-tip{
    margin: 180px 0 125px 0;
    text-align: center;
    .form-wrap{
      .tip{
        margin: 20px 0 125px 0;
        color: $gray;
        font-size: 16px;
      }
      .tabs-link{
        font-size: 13px;
      }
    }
  }

}
.account-login-form{
  .form-wrap {
    p {
      margin-bottom: 10px;
      line-height: 1em;
    }
    input[type='text'], input[type="password"], button {
      width: 400px;
      height: 39px;
      line-height: 39px;
      font-size: 15px;
      border-radius: 3px;
    }
    input[type='text'], input[type="password"] {
      border: 1px solid $color-border;
      padding: 0 8px;
    }
    .tip {
      font-size: 13px;
    }
    .verify {
      input {
        width: 236px;
        margin-right: 10px;
      }
      img {
        height: 37px;
        width: auto;
        vertical-align: middle;
        border-radius: 3px;
        overflow: hidden;
      }
      .reloadimg {
        color: $gray;
        font-size: 13px;
        &:hover {
          color: $color-hover;
        }
        &:active {
          color: $color-active;
        }
      }
    }
    .button {
      background-color: $color-main;
      color: $color-inverse;
      border: 0;
      letter-spacing: 0.1em;
      cursor: pointer;
    }
    .options {
      width: 400px;
      font-size: 13px;
      color: $gray;
      .find-pwd {
        margin-left: 20px;
        &:before {
          content: '';
          display: inline-block;
          width: 1px;
          height: 12px;
          background-color: $gray;
          margin-right: 20px;
          vertical-align: top;

        }
        a {
          color: $gray;
        }
      }
      label{
        [type="checkbox"]
        {
          vertical-align: top;
          position: relative;
          top: 1px;
          margin-right: 3px;
        }
      }
    }
    .tabs-link,.link {
      a {
        color: #20A0FF;
        text-decoration: underline;
        &:hover {
          color: $color-hover;
        }
        &:active {
          color: $color-active;
        }
      }
    }
    .link{
      a{text-decoration:none;}
    }
  }
  .three{
    width: 400px;
    margin-top: 40px;
    text-align: center;
    dt{
      position: relative;
      font-size: 12px;
      color: $gray;
      text-align: center;
      margin-bottom: 20px;
      &:before,&:after{
        content: "";
        display: block;
        width: 134px;
        height: 1px;
        position: absolute;
        top:6px;
        background-color: $color-border;
      }
      &:before{
        left: 0;
      }
      &:after{
        right: 0;
      }
    }
    dd{
      display: inline-block;
      margin: 0 7px;
    }

  }
}

