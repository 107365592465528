.banner_detail {
  position: relative;
  min-height: 400px;
  padding-top: 100px;
  &:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(#000, 0.4);
  }
}

.banner_border_bg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top:0;
  right: 0;
  right:0;
  bottom: 0;
  .banner_detail_bg {
    height: 100%;
    -webkit-filter: blur(60px);
    -moz-filter: blur(60px);
    -ms-filter: blur(60px);
    filter: blur(60px);
    background-size: 100% 400%;
  }
}

.banner_detail_form {
  position: relative;
  min-height: 300px;
  margin: 0 auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fff;
  width: 1200px;
  z-index: 1;
  .cover {
    height: 314px;
    width: 240px;
    margin-top: -45px;
    margin-left: 40px;
    float: left;
    box-shadow: 0px 0px 5px #444;
    border-radius: 6px;
    overflow: hidden;

    img {
      height: 314px;
      width: 240px;
    }
  }

  .info {
    float: left;
    width: 870px;
    padding-left: 20px;

    .title {
      font-size: 30px;
      color: #252525;
      margin-top: 35px;

      .right {
        float: right;
        font-size: 0;

        .score {
          font-size: 20px;
          color: #252525;
          margin-right: 10px;
          vertical-align: top;
        }

        .star {
          height: 21px;
          width: 21px;
          display: inline-block;
          background-image: url(../images/sd/detail-star.png);
          background-repeat: no-repeat;
          vertical-align: top;
          margin: 0 2px;
        }

        .active {
          background-image: url(../images/sd/detail-star-active.png);
        }
      }
    }

    .subtitle {
      font-size: 13px;
      color: #666666;
      margin-top: 10px;

      a {
        color: #666666;
      }
    }

    .tip {
      margin-top: 20px;

      .block {
        font-size: 13px;
        color: #999999;
        margin-right: 40px;

        span {
          color: #252525;
          margin-left: 10px;
        }
      }
    }

    .content {
      font-size: 13px;
      color: #666666;
      margin-top: 10px;
      line-height: 20px;
      width: 710px;
    }

    .bottom {
      margin-top: 25px;

      .btn-1 {
        background: #FFDB6F;
        border-radius: 3px;
        font-size: 16px;
        color: #874E12;
        height: 36px;
        line-height: 36px;
        width: 103px;
        text-align: center;
        display: inline-block;
        i {
          font-size: 1.4em;
          vertical-align: middle;
          margin-top: -3px;
          margin-right: 6px;
        }
      }

      .btn-1.active {
        color: #666;
        background: #f6f6f6;
        background-image: url(../images/sd/detail-collection-a.png);
        background-repeat: no-repeat;
        background-position: 12px 8px;
      }

      .btn-2 {
        margin-left: 6px;
        background: #FD113A;
        border-radius: 3px;
        font-size: 16px;
        color: #fff;
        height: 36px;
        line-height: 36px;
        width: 100px;
        text-align: center;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
      }

      .btn-3 {
        margin-left: 6px;
        background: #FFFEFE;
        border: 1px solid #D9D9D9;
        border-radius: 3px;
        font-size: 16px;
        color: #666;
        height: 36px;
        line-height: 36px;
        width: 120px;
        text-align: center;
        display: inline-block;
        padding-left: 20px;
        background-image: url(../images/sd/detail-phone.png);
        background-repeat: no-repeat;
        background-position: 9px 7px;
        position: relative;

        .code {
          position: absolute;
          left: -1px;
          top: 40px;
          display: inline-block;
          width: 120px;
          height: 150px;
          box-shadow: 0px 0px 5px #ccc;
          border-radius: 3px;
          display: none;
          background-color: #fff;
          z-index: 2;

          img {
            width: 110px;
            margin: 5px;
            display: block;
          }

          p {
            font-size: 13px;
            color: #252525;
            margin-top: 5px;
            height: 20px;
            line-height: 20px;
          }
        }
      }

      .btn-3:hover {
        .code {
          display: block;
        }
      }

      .btn-4 {
        float: right;
        font-size: 16px;
        color: #666666;
        padding-left: 20px;
        background-image: url(../images/sd/detail-share.png);
        background-repeat: no-repeat;
        background-position: 0px 2px;
      }
    }
  }
}