.vip-table{
  width: 100%;
  thead{
    td{
      height: 80px;
      text-align: center;
      font-size: 20px;
      color: $gray-dark;
      vertical-align: bottom;
      padding-bottom: 18px;
    }
  }
  tbody{
    td{
      height:80px;
      text-align: center;
      background-color: $color-inverse;
      color: $gray;
      border: 1px solid $color-border;
      i img{
        width: auto;
        height: 32px;
      }
      i.icon{
        font-size: 32px;
      }
      &:first-child{
        text-align: left;
        width: 200px;
        img{
          margin:-4px 16px 0  24px;
          vertical-align: middle;
        }
      }
    }
  }

}