.box{
  margin-top: 40px;
  .box-header{
    @include clearfix();
    font-weight: normal;
    margin-bottom: 15px;
    &.border-bottom{
      padding-bottom: 5px;
      border-bottom:1px solid $color-hr;
    }
    &.no-bottom{
      margin-bottom: 1px;
    }
    &>i,&>h1{
      font-size: 30px;
    }
    &>h1{
      display: inline-block;
      .small{
        font-size: 20px;
      }
    }
    &>i{
      width: 30px;
      color: $color-main;
      margin-right: 15px;
      vertical-align: text-bottom;
    }.icon-date{
        width: 24px;
         }
    &>small{
      font-size: 14px;
      color: $gray;
      vertical-align: text-bottom;
    }
    & .cat-tabs{
      display: inline-block;
      height: 26px;
      margin-bottom: -5px;
      li{
        float: left;
        margin-left: 40px;
        a{
          display: inline-block;
          font-size: 16px;
          color: $gray-dark;
          font-weight: bold;
          &.active{
            color: $color-main;
            &:after{
              content: ' ';
              display: block;
              width: calc(100% - 0.7em);
              height: 4px;
              background-color: $color-main;
              border-radius: 2px;
              margin: 3px auto 0 auto;
            }
          }
          &:hover{
            color: $color-hover;
          }
          &:active{
            color: $color-active;
          }
        }
      }
    }
    .more{
      display: inline-block;
      margin-top: 17px;
      font-size: 14px;
      color: $gray-dark;
      vertical-align: text-bottom;
      i{
        vertical-align: middle;
        font-size: 0.8em;
        margin-left: 3px;
        margin-top: -2px;
      }
    }
    .top-type{
      width: 120px;
      height: 24px;
      line-height: 24px;
      border-radius: 12px;
      background-color: $color-hr;
      a{
        display: block;
        float: left;
        height: 100%;
        color: $gray;
        font-size: 13px;
        padding: 0 8.5px;
        &.active{
          color: $color-inverse;
          background-color: $color-main;
          border-radius: 12px;
        }
      }
    }
    .back-link{
      display: inline-block;
      margin-top: 17px;
      font-size: 14px;
      color: $bg-info;
      vertical-align: text-bottom;
      text-decoration: underline;
    }

  }
  .box-body{
    @include clearfix();
    position: relative;
    
    .more{
      display: inline-block;
      margin-top: 17px;
      font-size: 14px;
      color: $gray-dark;
      vertical-align: text-bottom;
      i{
        vertical-align: middle;
        font-size: 0.8em;
        margin-left: 3px;
        margin-top: -2px;
      }
    }
  }
}
.box-head-sort{
  margin-top: 15px;
  &.pull-left{
    margin-top: 10px;
  }
  dt,dd{
    display: inline-block;
    font-weight: normal;
  }
  dt{
    color: $gray-light;
  }
  dd{
    color: $gray;
    i{
      font-size: 0.8em;
      margin : 0 3px 0 -1px;
    }
    &.active{
      color: $color-main;
    }
  }
}
.box-simple-tabs{
  display: inline-block;
  vertical-align: text-bottom;
  line-height: 1em;
  li{
    float: left;
    border-left:1px solid $gray-light;
    padding:0 20px;
    font-weight: bold;
    &:first-child{
      border-left:0;
    }
    a{
      color: $gray-dark;
      &:hover{
        color: $color-hover;
      }
      &:active{
        color: $color-active;
      }
      &.active{
        color: $color-main;
      }
    }
  }
}
.box-sub-head{
  padding: 10px 20px;
  .box-head-sort{
    margin-top: 4px;
  }
}
.box-booklist-edit-head{
  margin-top: -3px;
  font-size: 12px;
  form>span{
    margin-left: 6px;
  }
  .del-btn,.finish-btn{
    width: 100px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
  }
  .del-btn{
    border: 1px solid $color-border;
    background: $color-inverse;
    color:$gray;
  }
  .finish-btn{
    border: 1px solid $color-main;
    background: $color-main;
    color:$color-inverse;
  }
}