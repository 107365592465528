.user-repasswd{
  width: 400px;
  margin:60px auto;
  p{
    width: 100%;
    height: 39px;
    line-height: 39px;
    margin-bottom: 10px;
    input{
      width: 100%;
      height: 39px;
      border: 1px solid $color-border;
      border-radius: 4px;
      padding:0 8px;
      font-size: 15px;
      &.input_err{
        outline: none;
        border: 1px solid $color-main;
      }
    }
  }
  .tip{
    font-size: 13px;
    color: $gray;
    i{
      vertical-align: middle;
      margin:-3px 5px 0 0 ;
    }
  }

}