.cat-filter {
  margin: 20px auto;
  background-color: $color-inverse;
  padding: 0 20px;
  border-top: 2px solid $color-main;
  .field-wrap{
    padding-top:22px;
    border-bottom: 1px solid $color-hr;
  }
  .cat-list, .field-list {
    font-size: 13px;
    @include clearfix();
    dt, dd {
      display: block;
      float: left;
    }
    dt {
      margin-right: 6px;
    }
  }
  .cat-list {
    margin-top: 22px;
    padding-bottom: 12px;
    border-bottom: 1px solid $color-hr;
    a {
      position: relative;
      display: inline-block;
      color: $gray-dark;
      padding:0 6px;
      margin-left: 8px;
      &:hover {
        color: $color-hover;
      }
      &:active {
        color: $color-active;
      }
      &.active {
        color: $color-main;
        &:after {
          content: " ";
          display: block;
          position: absolute;
          bottom: -13px;
          left: 0px;
          width: 100%;
          height: 1px;
          background-color: $color-main;
        }
      }
    }
  }
  .field-list {
    margin-bottom: 16px;
    dt{
      height: 22px;
      line-height: 22px;
    }
    dd {
      a {
        position: relative;
        display:block;
        color: $gray-dark;
        height: 22px;
        min-width: 22px;
        text-align: center;
        line-height: 22px;
        border-radius: 11px;
        padding:0 6px;
        margin-left: 8px;
        &:hover {
          color: $color-hover;
        }
        &:active {
          color: $color-active;
        }
        &.active {
          background-color: $color-main;
          color:$color-inverse;
        }
      }
    }
  }
  .footer-btn{
    display: block;
    height: 47px;
    line-height: 47px;
    text-align: center;
    font-size: 13px;
    color: $gray-light;
    cursor: pointer;
    &:hover{
      color: $gray;
    }
    i{
      margin-left: 3px;
    }
  }
}