input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

body{padding-top: 74px;}

.header {
  height: 74px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: $color-inverse;
  border-bottom:1px solid #f3f3f3;
  transition: background-color .5s;
}
.header-logo{margin-top: 16px;float: left;}

.header-title {
  float: left;
  margin-left: 30px;
  i{
    vertical-align: top;
    margin-top: 28px;
  }
}

.header-title li{float: left;height: 74px;line-height: 74px;width: 58px;text-align: center;}
.header-title li a{font-size: 18px;color: #252525;}
.header-title li a.active{color: #FD113A;}
.header-title li a:hover{color: #FD113A;}
.header-class{display: inline-block;height: 18px;width: 18px;background-image: url(../images/sd/header-class.png);background-repeat: no-repeat;vertical-align: top;position: relative;top: 29px;left: -16px;}

.header-search {
  height: 38px;
  //border: 2px solid #FD113A;
  float: left;
  margin-top: 18px;
  margin-left: 20px;
  width: 402px;
  position: relative;
}

.header-search input {
  height: 38px;
  line-height: 38px;
  border:2px solid $color-main;
  outline: none;
  border-radius: 19px 0 0 19px;
  padding-left: 20px;
  font-size: 14px;
  width: 294px;
}

.header-search a {
  display: block;
  float: right;
  width: 108px;
  height: 38px;
  line-height: 38px;
  color: #fff;
  font-size: 16px;
  color: #FFFFFF;
  padding-left: 44px;
  border-radius: 0 19px 19px 0;
  background-color: #FD113A;
  background-image: url(../images/sd/header-search.png);
  background-repeat: no-repeat;
  background-position: 20px 10px;
}

.header-bar{
  float: left;
  margin-top: 20px;
  margin-left: 96px;
}
.header-bar li{float: left;width: 42px;text-align: center;}
.header-bar li p{margin-top:3px;}
.header-bar a {
  font-size: 12px;
  color: #929292;
  &:hover{
    color: $color-hover;
  }
  &:active{
    color: $color-active;
  }
}

.header-bar .vip p{color: #FFD21A;}
.header-bar .download{width: 55px;}
.header-vip{display: inline-block;height: 18px;width: 18px;background-image: url(../images/sd/header-vip.png);background-repeat: no-repeat;}
.header-bar i{
  font-size: 18px;
}
//.header-history{display: inline-block;height: 18px;width: 18px;background-image: url(../images/sd/header-history.png);background-repeat: no-repeat;}
//.header-collection{display: inline-block;height: 18px;width: 18px;background-image: url(../images/sd/header-collection.png);background-repeat: no-repeat;}
//.header-download{display: inline-block;height: 18px;width: 18px;background-image: url(../images/sd/header-download.png);background-repeat: no-repeat;}
.header-avatar{float: right;position: relative;top: 17px;width:40px;height:40px;border-radius: 20px;}
.header.opacity{
  border-bottom: 0;
  &{
    background-color: transparent;
    /* Rectangle: */
    background-image: linear-gradient(-180deg, #000000 -50%, rgba(0,0,0,0.00) 90%);
    .header-title li a{
      color: $color-inverse;
    }
    .header-title li a.active{color: #FD113A;}
    .header-title li a:hover{color: #FD113A;}
    .header-search input{
      border: 0;
      background-color: rgba($color-inverse,0.8);
    }
    .header-bar a {
      font-size: 12px;
      color: #d7d7d7;
      &:hover{
        color: $color-hover;
      }
      &:active{
        color: $color-active;
      }
    }

  }
}

.header.vip{
  background-color:#272728;
  .header-title li a{
    color: $color-vip;
    font-weight: normal;
    &:hover{
      color: $color-main
    }
  }
  .header-search input{
    border-color: $color-vip;
  }
  .header-search a{
    background-color: $color-vip;
  }
}
.header.vip .header-search-list a{background-color: inherit;}
.header-search-list{
  position: absolute;
  border: 1px solid #EDEDED;
  background-color: #fff;
  width: 278px;
  top: 38px;
  left: 15px;
  padding: 20px 0;
  padding-bottom: 5px;

  .title{
    padding: 0 20px;
    font-size: 16px;
    color: #999999;
  }

  ul{
    margin-top: 10px;
    overflow: hidden;

    li{
      padding: 0 20px;
      float: left;
      width: 100%;

      a{
        background: none;
        background-color: inherit;
        border-radius: 0;
        padding: 0;
        width: auto;
        float: none;
      }

      a.type_1{
        border-top: 1px dashed #D9D9D9;
        font-size: 14px;
        color: #252525;
        width: 100%;
        display: block;
        .red{
          color: #FD113A;
        }

        .left{
          width: 150px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .right{
          text-align: right;
          float: right;
          font-size: 14px;
          color: #999999;
          width: 85px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .type_2{
        width: 100%;
        overflow: hidden;
        display: none;
        padding: 10px 0;

        .cover{
          width: 60px;
          height: 80px;
          overflow: hidden;
          float: left;

          img{
            width: 60px;
          }
        }

        .info{
          float: left;
          padding-left: 10px;
          position: relative;
          width: 74%;
          height: 80px;

          .title{
            padding: 0;
            a{
              font-size: 14px;
              color: #252525;
              margin-top: 8px;
              height: 14px;
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            a:hover{
              color: #FD113A;
            }
          }

          .subtitle{
            font-size: 13px;
            color: #666666;
            margin-top: 8px;
            padding-left: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .tag{
            position: absolute;
            bottom: 5px;

            span{
              font-size: 13px;
              color: #666666;
              border: 1px dashed #D9D9D9;
              border-radius: 3px;
              padding: 2px 5px;
              background-color: #fff;
              margin-right: 5px;
            }
          }
        }
      }
    }

    li:hover,li.active{
      background-color: #ffeef1;
      a.type_1{
        border-top: none;
      }
      &+li{
        a.type_1{
          border-top: none;
        }
      }
    }

    li:first-child{

      .type_1{
        display: none;
        border-top: none;
      }

      .type_2{
        display: block;
        border-top: none;
      }
    }
  }
}
.header{
  overflow:visible;

  .container{
    overflow:visible;
  }
} 



.hover{
  position: relative;

  .red-sign{
      position: absolute;
      top: -8px;
      left: 24px;
      color: #fff;
      background-color: #fd113a;
      font-size: 8px;
      padding: 2px;
      padding-left: 3px;
      border-radius: 7px;
      display: none;
      min-width: 13px;
  }

  .hover-list{
    position: absolute;
    top: 30px;
    left: -112px;
    padding-top: 10px;
    width: 260px;
    display: none;
    &:before{
      content: ' ';
      display: block;
      width: 32px;
      height: 12px;
      margin: 0 auto;
      background-image: url('../images/arrow-up.png');
      z-index: 1;
      position: relative;
      top: 1px;
    }
    .block{
      border: 1px solid #EDEDED;
      border-radius: 4px;
      background-color: #fff;
      padding: 20px;
      padding-bottom: 40px;
      width: 260px;
      box-shadow: 0 0px 2px 0 rgba(0,0,0,0.1);
      display: none;
      .title{
        font-size: 16px;
        color: #252525;
        text-align: left;

        a{
          font-size: 13px;
          color: #999999;
          float: right;
        }
      }

      .login-title{
        font-size: 13px;
        color: #252525;
        margin-top: 10px;

        a{
          color: #FD113A;
          margin-left: 10px;
        }
      }
      ul{
        overflow: hidden;
        margin-top: 10px;

        li{
          border-bottom: 1px dashed #D9D9D9;
          float: left;
          width: 100%;
          width: 100%;
          overflow: hidden;
          padding: 10px 0;

          .cover{
            width: 60px;
            height: 80px;
            overflow: hidden;
            float: left;

            img{
              width: 60px;
            }
          }

          .info{
            float: left;
            padding-left: 10px;
            position: relative;
            height: 80px;
            width: 146px;
            p{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .title{
              margin-top: 8px;
              font-weight: bold;

              a{
                font-size: 14px;
                color: #252525;
                height: 14px;
                line-height: 14px;
                float: none;
              }

              a:hover{
                color: #FD113A;
              }
            }

            .subtitle{
              font-size: 13px;
              color: #666666;
              margin-top: 8px;
              text-align: left;
            }

            .tip{
              font-size: 13px;
              color: #666666;
              margin-top: 14px;
              text-align: left;

              .red{
                color: #FD113A;
              }
            }
          }
        }
      }

      .bottom{
        text-align: left;
        float: left;
        margin-top: 13px;
        color: #ff3f60;

        span{
          font-family: monospace;
        }
      }
    }
    .block.active{
      display: block!important;
    }
    .empty{
      height: 350px;
      display: block!important;
      position: relative;
    }
    .empty img{
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom: 0;
      margin: auto;
    }
  }

  .hover-user{
    position: absolute;
    top: 50px;
    left: -200px;
    padding-top: 10px;
    width: 270px;
    display: none;
    &:before{
      content: ' ';
      display: block;
      width: 32px;
      height: 12px;
      margin: 0 auto;
      background-image: url('../images/arrow-up.png');
      z-index: 1;
      position: relative;
      top: 1px;
      left: 82px;
    }
    .block{
      border: 1px solid #EDEDED;
      border-radius: 4px;
      background-color: #fff;
      padding: 20px;
      padding-bottom: 10px;
      width: 270px;
      box-shadow: 0 0px 2px 0 rgba(0,0,0,0.1);
      .top{
        overflow: hidden;
        padding-bottom: 10px;

        .cover{
          width: 40px;
          height: 40px;
          border-radius: 20px;
          float: left;
          overflow: hidden;

          img{
            width: 40px;
          }
        }

        .info{
          float: left;
          padding-left: 10px;
          width: 80%;

          .title{
            font-size: 14px;
            color: #444;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .subtitle{
            margin-top: 5px;
            font-size: 13px;
            color: #FD113A;

            .right{
              float: right;
              color: #FD113A;
            }
          }
        }
      }

      .line{
        font-size: 13px;
        color: #666666;
        margin: 8px 0;

        .right{
          float: right;
        }
      }

      .btn{
        display: block;
        width: 100%;
        border-radius: 17px;
        float: none;
        margin-top: 20px;
      }

      .bottom{
        padding-top: 20px;

        a{
          font-size: 13px;
          color: #666666;
        }

        .right{
          float: right;
        }
      }
    }
  }
}

.hover:hover{
  .hover-list{
    display: block;
  }

  .hover-user{
    display: block;
  }
}

.header_login.hover{
  float: right;
}