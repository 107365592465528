.banner_toplist{
  .warp{
    width:100%;
    height: 195.2px;
    padding-top:34px;
    text-align: center;
    background: url('../images/banner_toplist_bg.png');
    background-position: center center;
    background-size: 100% 100%;
  }
}