.box-wallet{
  .desc{
    p{
      margin: 12px 0;
      font-size: 13px;
      color: $gray;
      .title{
        font-size: 16px;
        font-weight: bold;
        color: $color-main;
      }
    }
  }
  .count-info{
    display: inline-block;
    margin-top: 5px;
    vertical-align: middle;
    font-size: 13px;
    color: $gray;
    .count{
      color: $gray-dark;
    }
  }
}
