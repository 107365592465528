.vip-chart-block{
  position: relative;
  z-index: 2;
  background-color: $color-inverse;
  height: 220px;
  padding:30px 30px;
  .left-side{
    float: left;
    width: 130px;
    text-align: center;
    img{
      width: 60px;
      height:60px;
      border-radius: 30px;
      margin: 10px 0 20px 0;
    }
    h2{
      font-size: 20px;
      margin-bottom: 10px;
      color: $gray-dark;
    }
    p{
      font-size: 13px;
      color: $gray;
      margin-bottom: 8px;
      &.text-left{
        margin-left: 15px;
        text-align: left;
      }
      .login-btn{
        display: inline-block;
        width: 80px;
        height:32px;
        line-height: 32px;
        border-radius: 16px;
        border: 1px solid $color-main;
        color: $color-main;
        &:hover{
          color: $color-hover;
          border: 1px solid $color-hover;
        }
        &:active{
          color:$color-active;
          border: 1px solid $color-active;
        }
      }
    }
  }
  .middle{
    float: left;
    width: 720px;
    padding:10px 60px 5px 60px;
    margin:0 0 0 35px;
    border: 1px solid $color-border;
    border-width: 0 1px ;
    text-align: center;
    &>p{
      font-size: 16px;
      color: $gray;
    }
  }
  .right-side{
    float: right;
    width: 220px;
    text-align: center;
    h2{
      font-size: 18px;
      color: $gray-dark;
      margin: 24px 0 15px 0;
      font-weight: bold;
    }
    p{
      font-size: 14px;
      color: $gray;
      margin-bottom: 24px;
    }
    .btn{
      float: none;
      width: 220px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
    }
  }
}