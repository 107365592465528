.down-app-page{
  .banner-top{
    height: 700px;
    background:#FCE04F url('../images/down-bg-icon.png') repeat center -5px;
    .wrap{
      padding: 118px 70px 118px 70px;
      .right{
        margin: 30px 0 0 130px;
        .logos{
          img{
            vertical-align:middle;
            margin-right: 25px;
          }
        }
        .txt{
          font-size: 32px;
          color: #000;
          margin: 20px 0  30px 10px;
        }
        .donws{
          margin-left: 6px;
          text-align: center;
          p{
            font-size: 16px;
            margin-top: 16px;
            color: #000;
          }
        }
        .down-btn{
          text-align: left;
          a{
            display: block;
            width: 260px;
            height: 60px;
            line-height: 60px;
            padding: 0 30px;
            margin:0 0 30px 40px;
            border-radius: 30px;
            font-size: 20px;
            background: $color-inverse;
            color: #000;
            img{
              vertical-align: middle;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
  .banner-box{
    position: relative;
    height: 700px;
    h1{
      font-size: 40px;
      color:#000;
      text-align: center;
      margin-top: 57px;
    }
    p{
      font-size: 20px;
      color:$gray;
      text-align: center;
      margin-top: 15px;
    }
    .swiper-container{
      width: 1020px;
      height: 365px;
      margin: 95px auto 20px auto;
      .swiper-slide{
        overflow: hidden;
        img{
          box-shadow: 0 0 8px #666;
        }
      }
    }
    .down-ios{
      display: block;
      position: absolute;
      top: 189px;
      left: 50%;
      margin-left: -117px;
      width: 230px;
      height: 459px;
      background-image: url('../images/down-ios.png');
      z-index: 1;
    }
  }
  .banner-bottom{
    .donws{
      width: 490px;
      margin: 60px auto 31px auto;
    }
    .down-btn{
      text-align: left;
      a{
        display: block;
        width: 260px;
        height: 60px;
        line-height: 60px;
        padding: 0 30px;
        margin:0 0 30px 65px;
        border-radius: 30px;
        font-size: 20px;
        background: $color-main;
        color: #fff;
        img{
          vertical-align: middle;
          margin-right: 15px;
        }
      }
    }
    .links{
      font-size: 20px;
      text-align: center;
      margin: 40px 0;
      span{
        display: inline-block;
        height: 40px;
        line-height: 40px;
        &:first-child{
          margin-right: 100px;
        }
        img,a{
          vertical-align: middle;
        }
        img{
          margin-right: 10px;
        }
        a{
          color: #000;
        }
      }
    }
  }
}