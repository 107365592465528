.vip-banner-big {
  height: 440px;
  background-size: cover;
  background-position: center center;
  .aside-box {
    width: 230px;
    height: 330px;
    padding: 20px;
    margin-top: (440px - 330px) /2;
    background: rgba(0, 0, 0, 0.70);
    .account-info {
      height: 80px;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        float: left;
        margin-right: 12px;
        margin-top: 5px;
      }
      p {
        color: #D7D7D7;
        font-size: 12px;
        margin: 0 0 5px 55px;
        &.name {
          font-size: 15px;
          color: $color-inverse;
          padding-top: 3px;
        }
        a {
          color: $color-inverse;
          text-decoration: underline;
          &:hover {
            color: $color-hover;
          }
          &:active {
            color: $color-active;
          }
        }
      }
    }
    .vip-desc {
      padding-top: 14px;
      background-image: url('../images/vip-aside-line.png');
      background-position: top center;
      background-repeat: no-repeat;
      p {
        height: 24px;
        line-height: 26px;
        font-size: 12px;
        color: #D7D7D7;
        margin-bottom: 20px;
        &:first-child {
          margin-top: 15px;
        }
      }
      .img-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 0 8px 0 -5px;
        float: left;
        img {
          width: auto;
          height: 100%;
        }
      }
    }
    .open-vip-btn {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-image: linear-gradient(-90deg, #EFDCB3 3%, #DAB77C 100%);
      border-radius: 20px;
      color: #222222;
      text-align: center;
      i {
        vertical-align: middle;
        margin: -4px 20px 0 0;
      }
    }
    .vip-login-btn {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      text-align: center;
      font-size: 14px;
      color: $color-vip;
      border: 1.5px solid $color-vip;

    }
    &.converted{
      width: 390px;
      height: 400px;
      margin-top: (440px - 400px) /2;
      h3{
        font-size: 30px;
        color: $color-inverse;
        margin: 20px auto;
        text-align: center;
        &:after,&:before{
          display: inline-block;
          content: '';
          width: 50px;
          height: 2px;
          background-color: $color-inverse;
          vertical-align: middle;
          margin: 0 20px;
        }
      }
      input,button{
        width: 100%;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
        border: 0;
        padding:0 12px;
        font-size: 16px;
        margin-bottom: 20px;
        outline: none;
      }
      button{
        cursor: pointer;
        background-color: $color-main;
        color: $color-inverse;
        &:hover{
          background-color: $color-hover;
        }
        &:active{
          background-color: $color-active;
        }
      }
      .desc{
        dt{
          font-size: 22px;
          color: $color-inverse;
          margin-top: 40px;
          margin-bottom: 10px;
        }
        dd{
          font-size: 14px;
          color: #d7d7d7;
          margin-bottom: 3px;

        }
      }
    }
  }
}