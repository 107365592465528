.user-sidebar{
  width: 241px;
  background:$color-inverse url('../images/user-sidebar-top.png') no-repeat top center;
  box-shadow: 2px 2px 6px #C0C0C0;
  .user-info{
    padding:40px 0 10px 0;
    text-align: center;
    border-bottom: 1px dashed $color-border;
    .avatar{
      width: 106px;
      height: 106px;
      overflow: hidden;
      margin: 0 auto;
      img{
        width:100%;
        height: 100%;
        border-radius: 50%;
        border:6px solid $color-border;
      }
    }
    h1{
      margin-top: 25px;
      font-size: 20px;
      color: $gray-dark;
      i{
        margin-left: 10px;
      }
    }
    p{
      color: $gray-dark;
      font-size: 13px;
      margin:15px 0 32px 0;
      min-height: 1em;
      padding: 0 20px;
    }
    span{
      display: block;
      float: left;
      width:25%;
      text-align: center;
      &.label{
        color: $gray-light;
      }
    }
  }
  .user-menu{
    padding: 40px 40px  0 40px;
    li{
      margin-bottom: 30px;
      a{
        font-size: 16px;
        color: $gray-dark;
        line-height: 24px;
        &:hover{
          color: $color-hover;
        }
        &:active{
          color: $color-active;
        }
        &.active{
          color: $color-main;
        }
        i{
          font-size: 24px;
          vertical-align: middle;
          margin-right: 20px;
          color: $gray-dark;
        }
        .sp{
          margin-left: -3px;
          margin-right: 12px;
        }
        span{
          vertical-align: middle;
        }
      }
    }
  }
}
