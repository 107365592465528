.scan-pay-box{
  margin-top: -40px;
  .order-info{
    position: relative;
    p{
      width: 600px;
      color: $gray-dark;
      font-size: 15px;
      margin-bottom: 10px;
      &>span{
        color:$gray;
      }
    }
    &>span{
      position: absolute;
      top:0;
      right: 0;
      font-size: 35px;
      color: $color-main;
    }
  }
  .goods-info{
    border: 1px solid $color-border;
    background-color: #F6F6F6;
    font-size: 13px;
    padding:10px 20px;
    color:$gray-dark;
    li{
      margin-top:5px;
      &:first-child{
        margin-top: 0;
      }
      label{
        margin-right: 10px;
      }
    }
  }
  .pay-wrap{
    @include clearfix();
    border:1px solid $color-border;
    padding:58px 272px;
    .pay-qr-box{
      text-align: center;
      dt{
        margin-bottom: 30px;
        font-size: 20px;
        img{
          vertical-align: text-top;
          margin-right: 20px;
        }
      }
      dd{
        margin-top: 20px;
        &.pay-qrimg{
          border:1px solid $color-border;
          padding: 15px;
          img{
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .back-link{
    font-size: 13px;
    @include a-link();
  }
}
