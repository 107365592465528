/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.sp-date {
  background-image: url(../images/sprite.png);
  background-position: -278px -88px;
  width: 13px;
  height: 13px;
}
.sp-icon-vip-black {
  background-image: url(../images/sprite.png);
  background-position: -249px -88px;
  width: 24px;
  height: 22px;
}
.sp-icon-vip {
  background-image: url(../images/sprite.png);
  background-position: -249px -49px;
  width: 36px;
  height: 34px;
}
.sp-m_book_new {
  background-image: url(../images/sprite.png);
  background-position: -249px 0px;
  width: 44px;
  height: 44px;
}
.sp-new_btn_1 {
  background-image: url(../images/sprite.png);
  background-position: 0px 0px;
  width: 244px;
  height: 62px;
}
.sp-new_btn_2 {
  background-image: url(../images/sprite.png);
  background-position: 0px -67px;
  width: 244px;
  height: 62px;
}
.sp-searchResult_btn_read {
  background-image: url(../images/sprite.png);
  background-position: 0px -134px;
  width: 120px;
  height: 36px;
}
.sp-tip {
  background-image: url(../images/sprite.png);
  background-position: -249px -115px;
  width: 20px;
  height: 20px;
}
.sp-vip-banner-line-left {
  background-image: url(../images/sprite.png);
  background-position: -125px -134px;
  width: 97px;
  height: 29px;
}
.sp-vip-banner-line-right {
  background-image: url(../images/sprite.png);
  background-position: 0px -175px;
  width: 97px;
  height: 29px;
}
