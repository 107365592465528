.mh-item {
  position: relative;
  background-color: $color-inverse;
  .mh-item-detali {
    .title {
      font-size: 14px;
      font-weight: bold;
      margin-top: 8px;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        color: $gray-dark;
        &:hover {
          color: $color-main;
        }
      }

    }
    p {
      font-size: 12px;
      line-height: 18px;
      span:first-child {
        display: inline-block;
        min-width: 36px;
      }
      &.zl {
        color: $gray!important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.chapter {
        color: $gray-dark;
      }
      a {
        @include a-link();
      }
      &.mh-label {
        span {
          display: inline-block;
          padding: 0 4px;
          margin-right: 4px;
          border-radius: 3px;
          border: 1px dashed $color-border;
          color: $gray;
        }
      }
    }
    .info {
      color: $gray;
      font-size: 13px;
      & > span {
        margin-right: 15px;
      }
      .new {
        color: $gray-dark;
      }
      a {
        color: $color-main;
        &:hover {
          color: $color-hover;
        }
        &:active {
          color: $color-active;
        }
      }
    }
  }
  .read-btn {
    display: block;
    min-width: 100px;
    height: 36px;
    padding:0 15px;
    line-height: 36px;
    text-align: center;
    background-color: $color-main;
    color: $color-inverse;
    font-size: 16px;
    border-radius: 3px;
    &:hover {
      background-color: $color-hover;
      color: $color-inverse;
    }
    &:active {
      background-color: $color-active;
      color: $color-inverse;
    }
  }
  .read-btn.line {
    background-color: $color-inverse;
    border: 1px solid $color-main;
    color: $color-main;
    &:hover {
      background-color: $color-inverse;
      border: 1px solid $color-hover;
      color: $color-hover;
    }
    &:active {
      background-color: $color-inverse;
      border: 1px solid $color-active;
      color: $color-active;
    }
  }

  .fav-btn {
    display: block;
    width: 103px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #FFDB6F;
    color: #874E12;
    font-size: 16px;
    border-radius: 3px;
    padding: 0 10px;
    i{
      font-size: 18px;
      margin:-4px 6px 0 0;
      vertical-align: middle;

    }
    &:hover {
      background-color: lighten( #FFDB6F,10%);
    }
    &.active {
      color: #666;
      background-color: #f6f6f6;
    }
  }
  .chapter-list{
    position: relative;
    margin-bottom: 49px;
    a{
      color: $gray;
      &:hover{
        color: $color-hover;
      }
      &:active{
        color: $color-active;
      }
    }
    ul{
      @include clearfix();
      margin-right: -10px;
      height: 49px;
      overflow: hidden;

      li{
        width: 120px;
        float: left;
        margin:10px 10px 0 0;
        font-size: 13px;
        @include text-hide;
      }
    }
    .chapter-more{
      position: absolute;
      bottom: -1px;
      right: 0;
      line-height: 1em;
      &:before{
        display: inline-block;
        content: ' ';
        width: 20px;
        height: 1em;
        background-color: red;
        vertical-align: middle;
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.81) 29%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 90%);
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.81) 29%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 90%);
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.81) 29%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 90%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
      }
      a{
        background-color: $color-inverse;
        color: $color-main;
        font-size: 13px;
      }
    }


  }
  .mh-tip-wrap {
    display: block;
    position: absolute;
    //top: -20px;
    //left: -30px;
    //z-index: -1;
    top: -40px;
    left: -30px;
    opacity: 0;
    transform: scale(0.73);
    transition: all .3s;
    -webkit-backface-visibility: hidden;
    max-height: 299px;
    overflow: hidden;
    z-index: 2;
    &.active {
      opacity: 1;
      top: -20px;
      left: -30px;
      max-height: 330px;
      transform: scale(1);
      box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
      z-index: 3;
    }
  }
  &.horizontal {
    & > .mh-cover {
      width: 167px;
      height: 220px;
      padding-top: 0;
      float: left;
    }
    .mh-item-detali {
      position: relative;
      float: left;
      width: 363px - 167px - 15px;

      height: 220px;
      &.autoHeight{
        height: auto;
      }
      margin-left: 15px;
      .title {
        font-size: 18px;
      }
      p {
        font-size: 13px;
        line-height: 24px;
      }
      .bottom {
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .desc {
        font-size: 13px;
        color: $gray;
        line-height: 1.5em;
        height: 4.5em;
        margin-top: 2px;
        margin-bottom:5px;
      }
    }
    .mh-tip-wrap {

    }
  }
  &.big {
    & > .mh-cover {
      width: 230px;
      height: 310px;
      padding-top: 0;
      float: left;
    }
    .mh-item-detali {
      width: 375px;
      height: 310px;
      .title {
        font-size: 20px;
        margin-bottom: 15px;
      }
      .mh-label {
        margin-bottom: 5px;
      }
      .desc {
        margin-top: 20px;
        max-height: 6em;
        span {
          display: block;
          color: $gray-dark;
        }
      }
    }
  }
  &.booklist {
    .mh-item-detali {
      width: 650px;
      .mh-label {
        margin-top: 6px;
      }
    }
    .fav-btn,.read-btn{
      float: left;
      margin-right: 10px;
    }
  }

}

//mh-itme-top
.mh-itme-top {
  position: relative;
  height: 50px;
  line-height: 50px;
  background-color: $color-inverse;
  color: $gray-dark;
  font-size: 14px;
  border-bottom: 1px dashed $color-hr;
  .num {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 14px;
    color: $gray;
    background-color: $bg;
    border: 1px solid $color-hr;
    border-radius: 12px;
    margin-right: 5px;
    &.num-1 {
      color: $color-inverse;
      background-color: #FEAF00;
      border: 1px solid #FF9900;
    }
    &.num-2 {
      color: $color-inverse;
      background-color: #FE8F00;
      border: 1px solid #FE7100;
    }
  }
  .limit {
    float: right;
    font-size: 12px;
    padding-right: 5px;
    .icon-arrow-a-up {
      color: #FF6882;
    }
    .icon-arrow-a-down {
      color: #83E751;
    }
  }
  & > a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $gray-dark;
    &:hover {
      color: $color-hover;
    }
    &:active {
      color: $color-active;
    }
  }
  .mh-tip-wrap {
    position: absolute;
    display: none;
    top: 0;
    left: 100%;
    line-height: 1em;
    z-index: 1;
    opacity: 0;
  }
  &:hover {
    .mh-tip-wrap.active {
      line-height: 1em;
      position: absolute;
      display: block;
      top: 0;
      left: 100%;
      z-index: 1;
    }
  }
}