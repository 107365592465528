/**
 * Default DropKick theme
 *
 * Feel free to edit the default theme
 * or even add your own.
 *
 * See the readme for themeing help
 *
 */

//***** Begin Theme, feel free to edit in here! ******

// One container to bind them...
.dk_container {
  font-size: 12px;
  margin-bottom: 0px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  &:focus {
    outline: 0;
  }
  &[disabled] {
    opacity:.4;
  }
  a {
    cursor: pointer;
    text-decoration: none;
  }
  &[disabled] a {
    cursor: not-allowed;
  }
}

// Opens the dropdown and holds the menu label
.dk_toggle {
  border: 1px solid #ccc;
  color: #333;
  padding: 10px 45px 10px 10px;
  text-shadow: #fff 1px 1px 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: border-color .5s;
  -moz-transition: border-color .5s;
  -o-transition: border-color .5s;
  transition: border-color .5s;
  position: relative;
  &:hover, &:focus {
    border-color: #8c8c8c;
  }
  &:after{
    position: absolute;
    top: 45%;
    right: 10px;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #4B4B4B;
  }
}
// Applied when the dropdown is focused
.dk_focus{
  .dk_toggle {
    border-color: #40b5e2;
    box-shadow: 0 0 5px #40b5e2;
    -moz-box-shadow: 0 0 5px #40b5e2;
    -webkit-box-shadow: 0 0 5px #40b5e2;
  }
}

// Applied whenever the dropdown is open
.dk_open {
  box-shadow: 0 0 5px #40b5e2;
  -moz-box-shadow: 0 0 5px #40b5e2;
  -webkit-box-shadow: 0 0 5px #40b5e2;
  //* Help: Dropdown menu is covered by something
  //* Try setting this value higher
  z-index: 10;
  .dk_toggle {
    background-color: #ececec;
    border-color: #8c8c8c;
    color: #ccc;
    box-shadow: inset 0 -2px 5px #ccc;
    border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
  }
}

// Applied whenever the dropdown is open at the top
.dk_open_top {
  .dk_toggle {
    border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0 0 5px 5px;
  }
  .dk_options, .dk_options_inner {
    border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
  }
}

//* The outer container of the options
.dk_options {
  background: #fefefe; // Removes transparent background on iOS5+ scroll
  box-shadow: rgba(0, 0, 0, .2) 0 2px 8px;
  -moz-box-shadow: rgba(0, 0, 0, .2) 0 2px 8px;
  -webkit-box-shadow: rgba(0, 0, 0, .2) 0 2px 8px;
  border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
  a {
    background-color: #fff;
    border-bottom: 1px solid #999;
    font-weight: bold;
    padding: 8px 10px;
  }
  a:hover, &:focus,
  .dk_option_current a {
    background-color: #0084c7;
    border-bottom-color: #004c72;
    color: #fff;
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, .5) 0 1px 0;
  }
  li:last-child a {
    border-bottom: none;
  }
  .disabled {
    cursor: default;
    a {
      color: #aaa;
    }
    &:hover, &:hover a,
    &:focus, &:focus a {
      cursor: default;
      background-color: #fff;
      border-bottom-color: #999;
      text-shadow: none;
    }
  }
}
// Inner container for options, this is what makes the scrollbar possible.
.dk_options_inner {
  max-height: 250px;
  border: 1px solid #8c8c8e;
  border-bottom-width: 2px;
  border-bottom-color: #999;
  color: #333;
  text-shadow: #fff 0 1px 0;
  border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
}

//******  End Theme ******

//***** Critical to the continued enjoyment of working dropdowns ******

.dk_container {
  display: none;
  float: left;
  a {
    outline: 0;
  }
}

.dk_toggle {
  display: -moz-inline-stack;
  display: inline-block;
  *display: inline;
  position: relative;
  zoom: 1;
}

.dk_open {
  position: relative;
  .dk_options {
    display: block;
  }
  .dk_label {
    color: inherit;
  }
}

.dk_options {
  display: none;
  margin-top: -1px;
  position: absolute;
  right: 0;
  width: 100%;
  a, a:link, a:visited{
    display: block;
  }
  .dk_optgroup {
    > span {
    
    }
  }
}

.dk_options_inner {
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
}


// Hides only dropkick <select> instances
.dk_container select {
  position: absolute;
  visibility: hidden;
}

//  nativeMobile override 
.dk_mobile {
  position: relative;
  select {
    display: block;
    width: 100%;
    height: 100%;
    top: 0; left: 0;
    visibility: visible;
    opacity: 0;
    appearance:none;
    -moz-appearance:none;
    -webkit-appearance:none;
  }
}

//***** End Critical to the continued enjoyment of working dropdowns ******
