.footer{
  background-color: $bg;
  color: $gray-light;
  font-size: 14px;
  padding:20px 0 35px 0;
  &.fixed{
    position: fixed;
    bottom: 0;
    left: 0;
  }
  @include clearfix();
  .footer-left{
    float: left;
    width: 60%;
    p{
      margin: 6px;
    }
    .footer-logo{
      margin-bottom: 35px;
    }

    .link{
      @include clearfix();
      span{
        display: block;
        float: left;
        padding:0 15px;
        line-height: 1em;
        border-left: 1px solid $gray-light;
        &:first-child{
          border: 0;
          padding-left: 0;
        }
        a{
          color: $gray-light;
        }
      }
    }
  }
  .footer-rigth{
    float: right;
    width: 40%;
    .qrcode-list{
      padding: 0;
      margin: 28px 0 0 0 ;
      @include clearfix();
      li{
        float: right;
      }
      li{
        padding: 0;
        margin: 0 0 0 40px;
        text-align: center;
        span{
          display: block;
          margin-top: 6px;
        }
      }

    }
  }
}