.topFIxedMenu{
  &.fixed{
    position: fixed;
    top:70px;
    left: 0;
  }
  &>div{
    position: relative;
   .top-menu{
     position: absolute;
     top:30px;
     left: -100px;
     width: 90px;
     padding:0;
     margin: 0;
     background-color: $color-inverse;
     box-shadow: 0 0 6px 0 rgba(0,0,0,0.10);
     li{
       a{
         display: block;
         height: 34px;
         line-height: 34px;
         color: $gray-dark;
         font-size: 14px;
         text-align: center;
         &:after{
           content: ' ';
           width: 60%;
           margin: 0 auto;
           display: block;
           border-top: 1px dashed $color-hr;
         }
         &.active,&:hover{
           background-color: $color-main;
           color: $color-inverse;
           &:after{
             display: none;
           }
         }
         &:active{
           background-color: $color-active;
           color: $color-inverse;
         }
       }
     }
   }
  }
}