.mh-list-swiper {
  height: 335px;
  position: relative;
  width: 1280px;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom:-20px;
  overflow: hidden;
  .swiper-container {
    width: 1280px;
    height: 100%;
    color: #fff;
    text-align: center;
    .swiper-wrapper{
      margin-top: 20px;
    }
    &.inTop{
      z-index: 2;
    }
  }

  .swiper-slide {
    height: 350px;
    .item-wrap{
      text-align:left;
      padding:20px 2.5px
    }
  }
  .pagination {
    position: absolute;
    z-index: 20;
    left: 10px;
    bottom: 10px;
    width: 510px - 20px;
    text-align: right;
  }

  .swiper-pagination-switch {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #D9D9D9;
    margin-right: 5px;
    cursor: pointer;
  }

  .swiper-visible-switch {
    background: $color-hover;
  }

  .swiper-active-switch {
    width: 23px;
    background: $color-main;
  }
  .next,.prev{
    position: absolute;
    top:20px;
    height: 293px;
    line-height: 293px;
    width: 40px;
    background-color: transparent;
    color: $gray;
    font-size: 26px;
    text-align: center;
    z-index: 1;
    &:hover{
      background-color: #F8F8F9;
    }
  }
  .next{
    right: 0;
  }
  .prev{
    left:0;
  }
}
