.box-user-vipinfo {
  .rand-info {
    width: 600px + 37px;
    padding: 60px 37px 0 0;
    border-right: 1px dashed $color-border;
    .desc {
      font-size: 13px;
      line-height: 1em;
      color: $gray;
      &>span{
        display: block;
        float: left;
        height: 1em;
        padding:0 15px;
        border-left:  1px solid $color-border;
        &:first-child{
          padding-left: 0;
          border-left: 0;
        }
      }
    }
  }
  .rand-renew{
    width: 210px;
    padding-top: 60px;
    color: $gray-dark;
    font-size: 13px;
    text-align: center;
    p{
      margin-bottom: 18px;
      span:first-child{
        padding-left: 5px;
      }
    }
    .label{
      color: $gray;
    }
    .date{
      font-size: 17px;
      font-weight: bold;
      color: $color-main;
    }
    .renew-btn{
      display: inline-block;
      float: none;
      width: 180px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
    }
  }
}