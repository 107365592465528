.vip-rand-chart{
  width: 100%;
  padding-bottom: 30px;
  .canvas-bg{
    position: relative;
    z-index: -1;
    width: 100%;
    height: 30px;
    margin-top: -30px;
    background-image: url('../images/vip-rand-bg.png');
    background-size: 100% 100%;
    img{
      width: 100%;
      height: 30px;
    }
  }
  canvas{
    display: none;
    width: 100%;
    height: 30px;
    background-color: red;
    background-size: 100% 100%;
  }
  table{
    width: 100%;
    font-size: 20px;
    td{
      text-align: center;
      color: $color-main;
      .rand-name{
        display: block;

      }
      .rand-line{
        display: block;
        width: 1px;
        height: 40px;
        border-left: 1px dashed $color-main;
        margin: 5px auto 3px auto;
      }
      .rand-val{
        display: block;
        color: $color-inverse;
        border-left: 1px dashed $color-inverse;
        height: 30px;
        line-height: 30px;
      }
      &:first-child .rand-val{
        border-left: 0;
      }
      &>div{
        position: relative;
      }
      .rand-current{
        position: absolute;
        bottom: -28px;
        left: 0;
        width: 100%;
        &:before{
          content: ' ';
          display: block;
          margin: 0 auto -6px auto ;
          width: 0;
          height: 0;
          border: 6px solid $color-main;
          border-color: transparent transparent $color-main transparent;
        }
        span{
          height: 25px;
          line-height: 25px;
          padding:0 5px;
          border-radius: 4px;
          background-color: $color-main;
          color:$color-inverse;
          font-size: 12px;
        }
      }
    }
  }
  //&.low{
  //  table{
  //    td{
  //      .rand-line{
  //        height: 35px;
  //      }
  //    }
  //  }
  //}
}