.vip-jumbotron{
  position: relative;
  padding: 40px;
  background-color: $color-inverse;
  .jumbotron-pane{
    @include clearfix();
    p{
      font-size: 18px;
      color: $gray;
      width: 800px;
      margin-bottom: 8px;
    }
    .jumbotron-pay-btn{
      display: block;
      float: right;
      width: 260px;
      height: 60px;
      line-height: 60px;
      font-size: 18px;
    }
  }
}