.box-con-8n3{
  position: relative;
  height: 600px;
  .box-8n3-pane{
    @include clearfix();
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    background-color: $color-inverse;
    display: none;
    &.active{
      display: block;
    }
    .box-cat-waterwheel{
      position: relative;
      width: 496px;
      float: left;
      overflow: hidden;
      .carousel-wrap{
        height: 330px;
        .carousel{
          img{
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
            border: 10px solid #ffffff;
            box-sizing: content-box;
          }
        }
        .imgbg-box{
          position: relative;
          display: block;
          margin: 0 auto;
          width: 250px;
          height: 330px;
          background-color: $color-inverse;
          z-index: 2;
        }
      }
      .detail{
        position: relative;
        dt,dd{
          margin-bottom: 10px;
        }
        .title{
          a{
            font-size: 20px;
            color: $gray-dark;
            font-weight: bold;
            &:hover{
              color: $color-hover;
            }
            &:active{
              color: $color-active;
            }
          }
          .mh-star{
            display: inline-block;
            float: none;
            margin-left: 30px;
          }
          .btn{
            display: block;
            float: right;
            width: 100px;
            height: 34px;
            line-height: 34px;
            background-color: $color-main;
            color: $color-inverse;
            text-align: center;
            font-size: 14px;
            border-radius: 4px;
            &:hover{
              color: #fff;
              background-color: $color-hover;
            }
            &:active{
              color: #fff;
              background-color: $color-active;
            }
          }
        }
        .info{
          color: $gray;
          font-size: 13px;
          &>span{
            margin-right: 15px;
          }
          .new{
            color: $gray-dark;
          }
          a{
            color: $color-main;
            &:hover{
              color: $color-hover;
            }
            &:active{
              color: $color-active;
            }
          }
        }
        .label{
          span{
            display: inline-block;
            height: 26px;
            line-height: 26px;
            padding: 0 4px;
            margin-right: 4px;
            font-size: 13px;
            color: $gray;
            border-radius: 3px;
            border: 1px dashed $color-border;
          }
        }
        .desc{
          color:$gray;
          font-size: 13px;
          line-height: 1.5em;
          max-height: 6em;
          overflow: hidden;
          margin-top: 20px;
          span{
            display: block;
          }
          p{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }
      }
      .waterwheel-body{
        position: relative;
        height: 263px;
        margin-top: 20px;
        @include clearfix();
        .pane{
          position: absolute;
          top:0;
          left: 0;
          width:100%;
          display: none;
          &.active{
            display: block;
          }
        }
      }
    }
    .box-cat-list{
      position: relative;
      margin:0 -2.5px;
      float: right;
      width: (167px +5px)*4;
      z-index: 2;
      li{
        width: 167px;
        margin: 0 2.5px;
        float: left;
        margin-bottom: 20px;
      }
    }
  }
}