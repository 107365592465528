.box-userinfo {
  .user-info-form {
    .avatar-wrap{
      min-height: 60px;
    }
    .avatar-edit-wrap {
      margin-top: -20px;
      margin-left: 95px;
      height: 0;
      overflow: hidden;
      padding-bottom: 1px;
      .avatar-edit {
        &>div{
          width: 240px;
          height: 240px;
          overflow: hidden;
        }
        .cropper-container{
          overflow: hidden;
        }
        .cropper-wrap-box{
          background-color: #fff;
          border: 1px solid $color-border;
        }
        .cropper-modal{
          background-color: #fff;
        }
      }
      .preview-wrap{
        span{
          font-size: 12px;
          vertical-align: top;
          &:first-child{
            margin-left:10px;
          }
          img{
            width: 110px;
            height: 110px;
            border-radius: 50%;
            overflow: hidden;
            border:4px solid $color-border;
          }
        }
      }
      .btn {
        float: none
      }
    }

    & > div {
      margin-bottom: 20px;
      font-size: 15px;
      vertical-align: middle;
      .avatar-demo {
        display: inline-block;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 3px solid $color-border;
          vertical-align: middle;
        }
        .inputImage {
          display: none;
        }
      }

      & > label {
        display: inline-block;
        width: 80px;
        text-align: right;
        color: $gray;
        margin-right: 10px;
        vertical-align: middle;
      }
      input {
        border: 1px solid $color-border;
        border-radius: 3px;
        height: 35px;
        min-width: 200px;
        padding: 5px;
        vertical-align: middle;
      }
      .date-icon {
        margin-left: -25px;
        vertical-align: middle;
        width: auto;
      }
      textarea {
        border: 1px solid $color-border;
        border-radius: 3px;
        width: 683px;
        height: 100px;
        padding: 5px;
        vertical-align: middle;
      }
      .save-btn, .upfile-btn {
        display: inline-block;
        float: none;
        cursor: pointer;
      }
      .upfile-btn {
        margin-right: 10px;
        background-color: #F6F6F6;
        border: 1px solid $color-border;
        color: #000;
        &:hover{
          background-color: $color-hover;
          border: 1px solid $color-hover;
          color: $color-inverse;
        }
      }
      .upfile-text-tip{
          font-size: 12px;
        margin: 5px 0 10px 0;
      }
      .select-wrap {
        display: inline-block;
        margin: 0 0 0 20px;
        &:first-child {
          margin: 0;
        }
        vertical-align: middle;
        select {
          width: 200px;
          height: 35px;
        }
      }
    }

    .input_err {
      outline: none;
      border: 1px solid $color-main;
    }
    .input_err_txt {
      display: block;
      margin: 5px 0 0 100px;
      color: $color-main;
      font-size: 12px;
    }
    .js_input_wrap {
      display: inline-block;
    }
    .radio-wrap {
      span {
        margin-left: 20px;
        &:first-child {
          margin: 0;
        }
      }
    }
  }
}