.brand-head{
  position: relative;
  height: 440px;
  margin-top: -74px;
  .head-wrap{
    position: absolute;
    bottom:0;
    left: 0;
    .head{
      padding: 24px 34px;
      border-radius: 4px 4px 0 0 ;
      background-color: $color-inverse;
      .info{
        .avatar{
          width: 60px;
          height: 60px;
          border-radius: 50%;
          float: left;
        }
        .title,p{
          margin-left: 70px;
        }
        .title{
          padding-top: 12px;
          font-size: 20px;
          color: $gray-dark;
          .label{
            height: 16px;
            line-height: 16px;
            font-size: 12px;
            padding:0 5px;
            color: $color-main;
            border-radius: 8px;
            border: 1px solid $color-main;
            vertical-align: middle;
            margin-left: 10px;
          }
        }
        p{
          color: $gray;
        }
      }
    }
  }
}