.mh-item-tip{
  width: 226px;
  height:330px;
  background-color: $color-inverse;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.20);
  .mh-item-tip-detali{
    padding:10px;
    .title{
      font-size: 18px;
      line-height: 21px;
      font-weight: bold;
      margin-bottom: 8px;
      a{
        display: inline-block;
        width: 130px;
        color: $gray-dark;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover{
          color: $color-main;
        }
      }
    }
    p{
      font-size: 12px;
      line-height: 18px;
      span:first-child{
        display: inline-block;
        min-width: 36px;
      }
      &.author{
        color:$gray;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        a{
          color: $gray;
          width: auto;
          padding-right: 3px;
          &:hover{
            color: $gray;
          }
        }
      }
      &.chapter{
        color: $gray-dark;
      }
      a{
        @include a-link();
        display: inline-block;
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
      }
    }
    .desc{
      font-size: 13px;
      color:$gray;
      line-height: 1.5em;
      height: 2.8em;
      margin-top: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
    .btn{
      display: block;
      width: 100%;
      height: 34px;
      margin-top: 10px;
      line-height: 34px;
      background-color: $color-main;
      color: $color-inverse;
      text-align: center;
      font-size: 14px;
      border-radius: 4px;
      &:hover{
        color: #fff;
        background-color: $color-hover;
      }
      &:active{
        color: #fff;
        background-color: $color-active;
      }
    }
  }

}