.banner_waterwheel {
  position: relative;
  height: 410px;
  background-image: url('../images/banner_waterwheel_bg.png');
  background-size: cover;
  .container {
    position: relative;
  }
  .carousel {
    margin-top: 15px;
    img{
      background: #D8D8D8;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
    }
  }
  .middle {
    position: relative;
    z-index: 5;
    width: 660px;
    height: 340px;
    margin:28px auto 0 auto;
    padding: 15px;
    background-color: $color-inverse;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
    .waterwheel-body {
      @include clearfix();
      position: absolute;
      top: 15px;
      left: 15px;
      background-color: $color-inverse;
      display: none;
      &.active {
        display: block;
      }
    }
    .big .mh-item-detali{
      margin-left: 15px;
    }
  }
  .next-btn, .prev-btn {
    display: block;
    position: absolute;
    top: 115px;
    width: 40px;
    height: 160px;
    line-height: 160px;
    background: rgba($bg-gray, 0.5);
    color: $gray-dark;
    text-align: center;
    font-size: 28px;
    cursor: pointer;
    z-index: 2;
    &:hover {
      background-color: rgba($color-border, 0.5);
    }
  }
  .next-btn {
    right: 0;
    border-radius: 5px 0 0 5px;
  }
  .prev-btn {
    left: 0;
    border-radius: 0 5px 5px 0;
  }
  .pagination {
    text-align: center;
    margin-top: 15px;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: $color-border;
      margin-right: 3px;
      cursor: pointer;
      &.active {
        width: 23px;
        background: $color-main;
      }
    }

  }
}