.dateProgressBar{
  height: 105px;
  .bar-wrap{
    padding-bottom:20px;
    &.fixed{
      position: fixed;
      top:74px;
      left: 0;
      z-index: 9;
    }
    .data-list{
      height: 80px;
      padding-top:20px;
      border-bottom:8px solid #F8F8F9;
      overflow: visible;
      background-color: $color-inverse;
      li{
        display: block;
        float: left;
        height: 65px;
        text-align: center;
        width: 85px;
        margin-left: 90px;
        &:first-child{
          margin-left: 35px;
        }
        &:after{
          content: "";
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-top: 7px;
          border-radius: 50%;
          background-color: $color-hr;
        }
        &.active:after{
          background-color: $color-main;
        }
        span{
          display: block;
          &.day{
            color: $gray-dark;
            font-size: 18px;
            margin-bottom: 8px;
          }
          &.date{
            color: $gray-light;
            font-size: 13px;
          }
        }
      }
    }
    &.fixed{
      .data-list{
        &:after{
          display: block;
          height: 110px;
          width: 100%;
          background-image: url("../images/dateProgressBar-line.png");
          position: absolute;
          bottom: 6px;
          left: 0;
          z-index: -1;
        }
      }
    }

  }
}