
html, body {
  margin: 0;
  padding: 0;
}

body {
  background-color: $bg-light;
  font-family: "PingFang SC", "Microsoft YaHei";
  min-width: 1200px;
}

* {
  box-sizing: border-box;
}

hr {
  background-color: $color-hr;
  width: 100%;
  height: 1px;
  border: 0;
  margin: 5px 0;
}

/*雪碧图*/
.sp {
  display: inline-block;
}
.icon-date{
  position: relative;
  span{
    width: 100%;
    text-align: center;
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -7.5px;
  }
}

.container {
  width: $ui-width;
  margin: 0 auto;
  overflow: hidden;
  @include clearfix();
}

.container-fluid {
  width: 100%;
  min-width: 1200px;
  overflow: hidden;
  @include clearfix();
}

.overflow-Show {
  overflow: visible;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}

.mt70 {
  margin-top: 70px;
}

.mb20 {
  margin-bottom: 20px;
}
.mb40 {
  margin-bottom: 40px;
}


.mr5 {
  margin-right: 5px;
}

.ml10 {
  margin-left: 10px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}
.plr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ptb35 {
  padding: 35px 0;
}

.pb20 {
  padding-bottom: 20px;
}

.pb40 {
  padding-bottom: 40px;
}

.color-main {
  color: $color-main;
}

.bg-gray {
  background-color: $bg-gray;
}

.a-link {
  @include a-link();
}

.ad-fullx90 {
  @include clearfix();
  width: $ui-width;
  height: 90px;
  overflow: hidden;
}

.mh-star {
  display: block;
  float: right;
  background-image: url(../images/star.png);
  width: 68px;
  height: 12px;
  background-position: 0 0;
  background-repeat: no-repeat;
  &.star-5 {
    background-position: 0 0;
  }
  &.star-4 {
    background-position: 0 -13px;
  }
  &.star-3 {
    background-position: 0 -28px;
  }
  &.star-2 {
    background-position: 0 -42px;
  }
  &.star-1 {
    background-position: 0 -56px;
  }
  &.star-0 {
    background-position: 0 -71px;
  }
}

.mh-star-line {
  display: inline-block;
  background-image: url(../images/star.png);
  width: 68px;
  height: 12px;
  background-position: 0 0;
  background-repeat: no-repeat;
  &.star-5 {
    background-position: 0 0;
  }
  &.star-4 {
    background-position: 0 -13px;
  }
  &.star-3 {
    background-position: 0 -28px;
  }
  &.star-2 {
    background-position: 0 -42px;
  }
  &.star-1 {
    background-position: 0 -56px;
  }
  &.star-0 {
    background-position: 0 -71px;
  }
}

.article-main {
  width: 856px;
}

.search-input {
  $h: 26px;
  height: $h;
  input, button {
    float: left;
    background-color: $color-inverse;
    border: 0;
    height: $h;
    line-height: $h;
    font-size: 12px;
    outline: none;
  }
  input {
    border-radius: $h/2 0 0 $h/2;
    width: 140px;
    padding: 0 12px;
  }
  button {
    border-radius: 0 $h/2 $h/2 0;
    width: 30px;
    line-height: $h+2;
  }

}

.btn {
  display: block;
  float: right;
  width: 100px;
  height: 34px;
  line-height: 34px;
  background-color: $color-main;
  color: $color-inverse;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  border: 0;
  &.full {
    width: 100%;
    float: none;
  }
  &:hover {
    color: #fff;
    background-color: $color-hover;
  }
  &:active {
    color: #fff;
    background-color: $color-active;
  }
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.table-head {
  @include clearfix();
  border-bottom: 1px solid $color-border;
  .table-tabs {
    float: left;
    li {
      float: left;
      margin-right: 3px;
      margin-bottom: -1px;
      a {
        display: block;
        width: 95px;
        height: 40px;
        line-height: 40px;
        border: 1px solid $color-border;
        border-radius: 8px 8px 0 0;
        color: $gray-dark;
        text-align: center;
        &:hover {
          color: $color-hover;
        }
        &:active {
          color: $color-active;
        }
        &.active {
          color: $color-inverse;
          background-color: $color-main;
          border: 1px solid $color-main;

        }
      }
    }
  }
}

.table-body {
  & > div {
    min-height: 450px;
  }
  table {
    width: 100%;
    font-size: 13px;

    th, td {
      height: 35px;
      padding: 0 10px;
      a {
        color: $gray;
        text-decoration: underline;
        &:hover {
          color: $color-hover;
        }
        &:active {
          color: $color-active;
        }
      }
    }
    tr {
      background-color: #e6e6e6;
      &:nth-child(2n) {
        background-color: $color-inverse;
      }
    }
    thead {
      color: $gray-light;
      tr {
        background-color: $color-inverse;
      }
    }
  }
}

/* iCheck plugin Line skin, green
----------------------------------- */
.icheckbox_line-green,
.iradio_line-green {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 13px;
  line-height: 17px;
  color: $gray;
  background: #F6F6F6;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  min-width: 40px;
  height: 35px;
  line-height: 35px;
  padding: 0 15px;
  text-align: center;
}

.icheckbox_line-green .icheck_line-icon,
.iradio_line-green .icheck_line-icon {
  position: absolute;
  top: 50%;
  left: 13px;
  width: 13px;
  height: 11px;
  margin: -5px 0 0 0;
  padding: 0;
  overflow: hidden;
  border: none;
}

.icheckbox_line-green.hover,
.icheckbox_line-green.checked.hover,
.iradio_line-green.hover {
  background: $color-hover;
  color: $color-inverse;
}

.icheckbox_line-green.checked,
.iradio_line-green.checked {
  background: $color-main;
  color: $color-inverse;
}

.icheckbox_line-green.checked .icheck_line-icon,
.iradio_line-green.checked .icheck_line-icon {
  background-position: -15px 0;
}

.icheckbox_line-green.disabled,
.iradio_line-green.disabled {
  background: lighten($color-main, 20%);
  color: $color-inverse;

  cursor: default;
}

.icheckbox_line-green.disabled .icheck_line-icon,
.iradio_line-green.disabled .icheck_line-icon {
  background-position: -30px 0;
}

.icheckbox_line-green.checked.disabled,
.iradio_line-green.checked.disabled {
  background: lighten($color-main, 20%);
  color: $color-inverse;
}

.icheckbox_line-green.checked.disabled .icheck_line-icon,
.iradio_line-green.checked.disabled .icheck_line-icon {
  background-position: -45px 0;
}

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_line-green .icheck_line-icon,
  .iradio_line-green .icheck_line-icon {
    -webkit-background-size: 60px 13px;
    background-size: 60px 13px;
  }
}

.dk_container {
}

/* Two custom themes */
.dk_theme_orange {
  background: #f1f1f1; /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2YxZjFmMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUxJSIgc3RvcC1jb2xvcj0iI2UxZTFlMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNmY2ZjYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #ffffff 0%, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(50%, #f1f1f1), color-stop(51%, #e1e1e1), color-stop(100%, #f6f6f6)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffffff 0%, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffffff 0%, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffffff 0%, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6 100%); /* IE10+ */
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 );*/
  /* IE6-9 */
  /* Avoid filter! It breaks IE<8 */
  background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6 100%); /* W3C */
}

.dk_theme_orange .dk_toggle {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e1e1e1', GradientType=0) \9
; /* IE < 8 */
}

.dk_theme_orange .dk_label {
  display: block \9
; /* IE < 8 */
}

.dk_theme_orange .dk_options a:hover,
.dk_theme_orange .dk_option_current a {
  background-color: #E15A01;
  border-bottom-color: #604A42;
  color: #fff;
  text-shadow: #604A42 0 1px 0;
}

.dk_theme_orange .dk_toggle,
.dk_theme_orange.dk_open .dk_toggle {
  background-color: transparent;
}

.dk_theme_orange.dk_focus .dk_toggle {
  box-shadow: 0 0 5px #E15A01;
  -moz-box-shadow: 0 0 5px #E15A01;
  -webkit-box-shadow: 0 0 5px #E15A01;
}

.dk_theme_black {
  background: #6e7774; /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2FlYmNiZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzZlNzc3NCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUxJSIgc3RvcC1jb2xvcj0iIzBhMGUwYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwYTA4MDkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #aebcbf 0%, #6e7774 50%, #0a0e0a 51%, #0a0809 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #aebcbf), color-stop(50%, #6e7774), color-stop(51%, #0a0e0a), color-stop(100%, #0a0809)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #aebcbf 0%, #6e7774 50%, #0a0e0a 51%, #0a0809 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #aebcbf 0%, #6e7774 50%, #0a0e0a 51%, #0a0809 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #aebcbf 0%, #6e7774 50%, #0a0e0a 51%, #0a0809 100%); /* IE10+ */
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#aebcbf', endColorstr='#0a0809',GradientType=0 );*/
  /* IE6-9 */
  /* Avoid filter! It breaks IE<8 */
  background: linear-gradient(to bottom, #aebcbf 0%, #6e7774 50%, #0a0e0a 51%, #0a0809 100%); /* W3C */
}

.dk_theme_black .dk_toggle {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#aebcbf', endColorstr='#0a0809', GradientType=0) \9
; /* IE < 8 */
}

.dk_theme_black .dk_label {
  display: block \9
; /* IE < 8 */
}

.dk_theme_black .dk_toggle,
.dk_theme_black.dk_open .dk_toggle {
  background-color: transparent;
  color: #fff;
  text-shadow: none;
}

.dk_theme_black .dk_toggle:after {
  border-top-color: #fff;
}

.dk_theme_black .dk_options a {
  background-color: #333;
  color: #fff;
  text-shadow: none;
}

.dk_theme_black .dk_options a:hover,
.dk_theme_black .dk_option_current a {
  background-color: #E15A01;
  color: #fff;
  text-shadow: #604A42 0 1px 0;
}

/* Cancel Filter for IE9, that supports svg background */
@media all and (min-width: 0) {
  .dk_theme_orange .dk_toggle,
  .dk_theme_black .dk_toggle {
    filter: none;
  }
}

.box404 {
  @include clearfix();
  width: 354px;
  margin: 234px auto 60px auto;
  font-size: 13px;
  color: $gray-dark;
  .tip-text {
    margin: 10px auto 50px auto;
    line-height: 1.5em;
  }
  a {
    color: #20A0FF;
    text-decoration: underline;
    &:hover {
      color: $color-hover;
    }
    &:active {
      color: $color-active;
    }
  }
}

.about-page {
  .head {
    font-size: 20px;
    padding-bottom: 10px;
    margin: 40px 0 20px 0;
    border-bottom: 1px solid $color-border;
  }
  h2{
    font-size: 15px;
    color: $gray-dark;
    margin: 20px 0;
  }
  pre{
    color: $gray-light;
    font-size: 13px;
    line-height: 1.6em;
  }
  .describe {
    font-size: 13px;
    color: $gray;
    line-height: 1.7em;
    margin-right: 115px;
  }
  .linkman-list {
    @include clearfix();
    li {
      float: left;
      width: 33.33%;
      margin-bottom: 20px;
      h2 {
        font-color: $gray-dark;
        font-size: 15px;
        margin-bottom: 12px;
      }
      p {
        font-size: 13px;
        color: $gray-light;
        margin-bottom: 10px;
        span {
          color: $gray-dark;
        }
      }
    }
  }
  .forms {
    padding: 30px 50px;
    .tabs {
      @include clearfix();
      margin-bottom: 20px;
      li {
        float: left;
        font-size: 16px;
        border-left: 1px solid $gray-dark;
        line-height: 1em;
        padding: 0 18px;
        &:first-child {
          border-left: 0;
          padding-left: 0;
        }
        a {
          color: $gray-dark;
          &.active {
            color: $color-main;
          }
          &:hover {
            color: $color-hover;
          }
          &:active {
            color: $color-active;
          }
        }
      }
    }
    .panel-wrap {
      position: relative;
    }
    .panel {
      color: $gray;
      font-size: 13px;
      display: none;
      &.active{
        display: block;
      }
      p {
        margin-top: 8px;
        a {
          color: #20A0FF;
          &.active {
            color: $color-main;
          }
          &:hover {
            color: $color-hover;
          }
          &:active {
            color: $color-active;
          }
        }
      }
      textarea {
        width: 100%;
        height: 165px;
        border-radius: 4px;
        border: 1px solid $color-border;
        padding: 5px;
        vertical-align: top;
        & + span {
          margin-left: 2px;
          margin-right: -10px;
          display: inline-block;
        }
      }
      input[type='text'] {
        width: 200px;
        margin-right: 8px;
        height: 28px;
        line-height: 28px;
        padding: 0 5px;
        border-radius: 4px;
        border: 1px solid $color-border;
        &+span{
          margin-left: -10px;
          vertical-align: top;
        }
      }
      .upfile-box{
        display: inline-block;
        position: relative;
        overflow: hidden;
        input{
          position: absolute;
          top:0;
          left: 0;
          opacity: 0;
        }
      }
      .icon {
        vertical-align: middle;
        font-size: 1.6em;
        vertical-align: text-top;
      }
      .text-right {
        text-align: right;
      }
      .url[type='text'] {
        width: 100%;
        margin-right: 0;
      }
      .button{
        width: 100px;
        height: 36px;
        border: 0;
        background-color: $color-main;
        color: #fff;
        font-size: 16px;
        margin-left: 13px;
        &:hover{
          background-color: $color-hover;
        }
        &:active{
          background-color: $color-hover;
        }
      }
      .labels label{
        margin-right:20px;
        input{
          margin-right: 5px;
        }
      }
    }
  }
}

.modal-wrap{
  position: fixed;
  top:0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(#000,0.6);
  z-index: 999;

  .modal{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    .modal-body{
      position: relative;
      text-align: left;
      .close{
        position: absolute;
        top:20px;
        right: 20px;
        color: $gray;
        cursor: pointer;
      }
      &.vip-modal{
        width: 340px;
        background-color: $color-inverse;
        margin: 0 auto;
        padding:20px;
        h2{
          font-size: 16px;
          font-weight: bolder;
          color: $gray-dark;
          margin-bottom: 20px;
        }
        .tip{
          line-height: 32px;
          font-size: 14px;
          text-align: center;
          margin:25px 0 22px 0;
          img{
            width: 32px;
            height: 32px;
            vertical-align: middle;
            margin-right: 20px;
          }
        }
        .desc{
          font-size: 14px;
          color: $gray-dark;
          line-height: 1.6em;
        }
        .foot{
          margin-top: 20px;
          font-size: 14px;
          color: $gray;
          .other-btn{
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding:0 10px;
            border: 1px solid $color-border;
            color:$gray;
            background: $color-inverse;
            border-radius: 4px;
            margin-right: 15px;
          }
          .success-btn{
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding:0 10px;
            border: 0;
            color:$color-inverse;
            background-color: $color-main;
            border-radius: 4px;
            &:hover{
              background-color: $color-hover;
            }
          }
        }
      }
      &.login-modal{
        width: 440px;
        background-color: $color-inverse;
        margin: 0 auto;
        padding:20px;
        h1{
          font-size: 30px;
          color:$gray-dark;
          margin-bottom: 30px;
          text-align: center;
        }
      }
      &.paydetail-modal{
        width:640px;
        height: 520px;
        background-color: $bg-gray;
        border: 1px solid $color-border;
        margin: 0 auto;
        padding:20px;
        h1{
          font-size: 26px;
          color: $gray-dark;
          text-align: center;
          margin-bottom: 20px;
        }
        &>div{
          background-color: $color-inverse;
          border: 1px solid $color-border;
          .logs{
            height: 350px;
            overflow: auto;
            table{
              font-size: 15px;
              width: 100%;
              td{
                height: 43px;
                color: $gray;
                padding-left: 50px;
                border-top: 1px solid $color-border;
              }
              th{
                height: 35px;
                padding-left: 50px;
                color: $gray-dark;
              }

            }
          }
          .font{
            font-size: 13px;
            text-align: center;
            margin-bottom: -15px;
            padding-top: 10px;
          }
          button{
            display: block;
            width: 100px;
            height: 36px;
            border: 0;
            background-color: $color-main;
            color: $color-inverse;
            font-size: 16px;
            margin: 20px auto;
            border-radius: 3px;
            &:hover{
              background-color: $color-hover;
            }
          }
        }
      }
    }
  }
}

.top_container{
  margin-top: 20px!important;
  padding-left: 10px;
  .mh-item .mh-item-detali .title{
    margin-top: 16px;
  }
}

